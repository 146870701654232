import { CHART_TYPES } from 'helpers/Constants'

export const GET_CHART_SETTINGS_ACTION = 'GET_CHART_SETTINGS_ACTION'
export const SAVE_CHART_SETTINGS_ACTION = 'SAVE_CHART_SETTINGS_ACTION'
export const RESET_CHART_SETTINGS_ACTION = 'RESET_CHART_SETTINGS_ACTION'

export type ChartSettingsType = {
  props: {
    title: string
    xAxisTitle?: string
    yAxisTitle?: string
    keywordTitle?: string
    countTitle?: string
    isRevenue?: boolean
  }
  dataKey: string
  type: (typeof CHART_TYPES)[keyof typeof CHART_TYPES]
  Component?: (props) => JSX.Element
  visible?: boolean
}

export type CustomerAnalyticsState = {
  chartSettings: ChartSettingsType[]
}
