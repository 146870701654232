import { ANY_PATH, VIEW_SUBPATH } from 'helpers/Constants/path'
import { Route, Routes } from 'react-router-dom'
import ViewOrder from './ViewOrder'
import OrderList from './OrderList'

export default function Orders() {
  return (
    <Routes>
      <Route path={VIEW_SUBPATH} element={<ViewOrder />} />
      <Route path={ANY_PATH} element={<OrderList />} />
    </Routes>
  )
}
