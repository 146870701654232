import { Box, SxProps, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { COLORS } from 'styles'
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress'
import { fontWeight } from 'styles/theme'
import { WidgetCard } from 'components/UI'

type ListDataType = {
  /**
   * unique id for the list
   */
  id: number

  /**
   * list item title
   */
  keyword: string

  /**
   * list item value
   */
  count: number

  /**
   * percentage value that needs to be displayed
   */
  percentageValue?: number
}

interface TopXListProps {
  /**
   * chart title
   */
  title: string

  /**
   * title that will be displayed on top of list
   * @default 'Keyword'
   */
  keywordTitle?: string

  /**
   * title that will be displayed on top of list
   * @default 'Count'
   */
  countTitle?: string

  /**
   * data series
   */
  series: ListDataType[]
}

export default function TopXList({
  title,
  series,
  keywordTitle,
  countTitle,
}: TopXListProps) {
  const [list, setList] = useState<ListDataType[]>([])

  useEffect(() => {
    const maxValue = Math.max(...series.map((item) => item?.count))
    setList(
      series.map((item) => ({
        ...item,
        percentageValue: (100 * item.count) / maxValue,
      }))
    )
  }, [series])

  return (
    <WidgetCard title={title} fullHeight>
      <Box sx={topXListStyle.listItemsWrapper}>
        <Box sx={topXListStyle.listItem}>
          <Box sx={topXListStyle.itemLabelWrapper}>
            <Typography sx={topXListStyle.itemHeader}>
              {keywordTitle ?? 'Keyword'}
            </Typography>
          </Box>
          <Box sx={topXListStyle.itemResultWrapper}>
            <Typography sx={topXListStyle.itemHeader}>
              {countTitle ?? 'Count'}
            </Typography>
          </Box>
        </Box>

        {list.map((item) => (
          <Box sx={topXListStyle.listItem} key={item.id}>
            <Box sx={topXListStyle.itemLabelWrapper}>
              <Typography sx={topXListStyle.itemLabel}>
                {item.keyword}
              </Typography>
            </Box>
            <Box sx={topXListStyle.itemResultWrapper}>
              <Box sx={topXListStyle.itemValueWrapper}>
                <Typography sx={topXListStyle.itemValue}>
                  {item.count}
                </Typography>
              </Box>

              <Box sx={topXListStyle.itemProgressWrapper}>
                <Progress value={item.percentageValue} />
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </WidgetCard>
  )
}

interface ProgressProps {
  /**
   * number specified in percentage value
   */
  value: number
}

function Progress({ value }: ProgressProps) {
  return (
    <LinearProgress
      sx={topXListStyle.progress}
      variant="determinate"
      value={value}
    />
  )
}

interface TopXListStyleType {
  listItemsWrapper: SxProps
  listItem: SxProps
  itemHeader: SxProps
  itemLabelWrapper: SxProps
  itemLabel: SxProps
  itemResultWrapper: SxProps
  itemValueWrapper: SxProps
  itemValue: SxProps
  itemProgressWrapper: SxProps
  progress: SxProps
}

const SIZES = {
  LABEL: 50,
  PROGRESS: 150,
}

const topXListStyle: TopXListStyleType = {
  listItemsWrapper: {
    my: 3,
  },
  listItem: {
    display: 'flex',
    my: 2,
  },
  itemHeader: {
    fontSize: 14,
    color: COLORS.FADED_TEXT,
    fontWeight: fontWeight.medium,
  },
  itemLabelWrapper: {
    width: `calc(100% - ${SIZES.LABEL}px - ${SIZES.PROGRESS}px)`,
  },
  itemLabel: {
    fontSize: 14,
    fontWeight: fontWeight.bold,
    color: COLORS.GUNMETAL_BLUE,
    pr: 1,
  },
  itemResultWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  itemValueWrapper: {
    width: SIZES.LABEL,
    px: 1,
  },
  itemValue: {
    fontSize: 12,
    fontWeight: fontWeight.medium,
  },
  itemProgressWrapper: {
    width: SIZES.PROGRESS,
  },
  progress: {
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: 'transparent',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: COLORS.PRIMARY,
    },
  },
}
