import { Box, Grid } from '@mui/material'
import { Card, CardSubTitle, PageTitle } from 'components/UI'
import { useCallback, useEffect, useState } from 'react'
import {
  Contacts as ContactsIcon,
  Email as EmailIcon,
  PhoneIphone as PhoneIphoneIcon,
  CalendarMonth as CalendarMonthIcon,
  AppRegistration as AppRegistrationIcon,
  Apartment as ApartmentIcon,
  LocationCity as LocationCityIcon,
  House as HouseIcon,
  Public as PublicIcon,
  FmdGood as FmdGoodIcon,
} from '@mui/icons-material'
import { useSearchParams } from 'react-router-dom'
import { usersService } from 'services'
import { WidgetCard } from './Widgets'

interface userDataObject {
  AddressLine1: string
  AddressLine2: string
  City: string
  Country: string
  CreatedDate: string
  DOB: string
  Email: string
  FirstName: string
  LastName: string
  MiddleName: string
  PostalCode: string
  Province: string
  TitleName: string
  loginProvider: string
  primaryPhone: string
}

export default function ViewUser() {
  const [searchParams] = useSearchParams()
  const userId = searchParams.get('id')
  const [userData, setUserData] = useState<userDataObject>()

  const getUserDetails = useCallback(async () => {
    const response = await usersService.userDetails({
      params: {
        userId,
      },
    })
    if (response) {
      setUserData(response.data)
    }
  }, [userId])

  useEffect(() => {
    getUserDetails()
  }, [userId, getUserDetails])

  return (
    <Box>
      <Box>
        <PageTitle>{CONTENT_LABELS.pageTitle}</PageTitle>
      </Box>
      <Grid container spacing={2}>
        <Grid item md={6} sm={12} xs={12}>
          <Card>
            <Box sx={{ py: 2 }}>
              <CardSubTitle
                label={`${CONTENT_LABELS.userInfo}`}
                sx={{ color: '#444444', fontSize: '1rem' }}
              />
            </Box>
            <WidgetCard
              title="Created Date"
              value={userData?.CreatedDate}
              Icon={CalendarMonthIcon}
            />
            <WidgetCard
              title="Name"
              value={`${userData?.TitleName} ${userData?.FirstName} ${userData?.MiddleName} ${userData?.LastName}`}
              Icon={ContactsIcon}
            />
            <WidgetCard
              title="Birth Date"
              value={userData?.DOB}
              Icon={CalendarMonthIcon}
              componentWrapperStyles={{ border: 'none' }}
            />
          </Card>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Card>
            <Box sx={{ py: 2 }}>
              <CardSubTitle
                label={`${CONTENT_LABELS.contactInfo}`}
                sx={{ color: '#444444', fontSize: '1rem' }}
              />
            </Box>
            <WidgetCard
              title="Email"
              value={userData?.Email}
              Icon={EmailIcon}
            />
            <WidgetCard
              title="Phone"
              value={userData?.primaryPhone}
              Icon={PhoneIphoneIcon}
            />
            <WidgetCard
              title="App Registration"
              value={userData?.loginProvider}
              Icon={AppRegistrationIcon}
              componentWrapperStyles={{ border: 'none' }}
            />
          </Card>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Card>
            <Box sx={{ py: 2 }}>
              <CardSubTitle
                label={`${CONTENT_LABELS.addressDetails}`}
                sx={{ color: '#444444', fontSize: '1rem' }}
              />
            </Box>
            <WidgetCard
              title="Street Address"
              value={userData?.AddressLine1}
              Icon={ApartmentIcon}
            />
            <WidgetCard
              title="Apt, Suite"
              value={userData?.AddressLine2}
              Icon={HouseIcon}
            />
            <WidgetCard
              title="City"
              value={userData?.City}
              Icon={LocationCityIcon}
            />
            <WidgetCard
              title="Province/Country"
              value={`${userData?.Province} ${userData?.Country}`}
              Icon={PublicIcon}
            />
            <WidgetCard
              title="Postal Code"
              value={userData?.PostalCode}
              Icon={FmdGoodIcon}
              componentWrapperStyles={{ border: 'none' }}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

const CONTENT_LABELS = {
  pageTitle: 'User Details',
  userInfo: 'User Information',
  contactInfo: 'Contact Information',
  addressDetails: 'Address Details',
}
