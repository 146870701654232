import {
  Tooltip as MUITooltip,
  TooltipProps as MUITooltipProps,
} from '@mui/material'

interface TooltipProps extends MUITooltipProps {
  /**
   * removed border from chip
   * @default false
   */
  title: JSX.Element
}

export default function Tooltip({
  title,
  children,
  sx,
  ...otherProps
}: TooltipProps) {
  return (
    <MUITooltip title={title} {...otherProps}>
      {children}
    </MUITooltip>
  )
}
