import { SplineChart } from 'components/UI'
import ChartWidgetWrapper, { ChartWidget } from './ChartWidgetWrapper'

export default function SplineChartWidget({
  title,
  ...otherProps
}: ChartWidget) {
  return (
    <ChartWidgetWrapper title={title}>
      <SplineChart
        chart={{
          height: '300px',
        }}
        {...otherProps}
      />
    </ChartWidgetWrapper>
  )
}
