import { object, string } from 'yup'

export const FORM_FIELDS = {
  two_factor_code: 'two_factor_code',
}

export const formFieldsConfig = {
  initialValues: {
    two_factor_code: null,
  },
  two_factor_code: {
    placeholder: 'Authentication Code',
    name: FORM_FIELDS.two_factor_code,
    type: 'number',
  },
}

export const validationSchema = object().shape({
  two_factor_code: string()
    .required('Please enter authentication code')
    .matches(/^[0-9]+$/, 'Authentication Code must be only digits')
    .min(6, 'Authentication Code Should consist 6 Digit')
    .max(6, 'Authentication Code Should consist 6 Digit'),

  // number()
  //   .min(6, "Authentication Code Should consist 6 Digit")
  //   .max(6, "Authentication Code Should consist 6 Digit")
  //   .required("Please enter authentication code."),
})
