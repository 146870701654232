import { Box, Typography } from '@mui/material'
import { Card } from 'components/UI'
import { SvgIconComponent } from '@mui/icons-material'
import { fontWeight } from 'styles/theme'
import { COLORS } from 'styles'

interface SimpleStateCardProps {
  /**
   * title of the card
   */
  title: string

  /**
   * icon that shows up on left side
   */
  Icon: SvgIconComponent

  /**
   * card value
   */
  value: string
}

export default function SimpleStateCard({
  title,
  Icon,
  value,
}: SimpleStateCardProps) {
  return (
    <Card>
      <Box
        sx={{
          display: 'flex',
          maxWidth: '250px',
          // justifyContent:'center',
          p: 1,
        }}
      >
        {/* Icon */}
        <Box>
          <Icon sx={{ color: COLORS.FADED_TEXT }} />
        </Box>

        <Box
          sx={{
            px: 1,
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: 14,
                color: COLORS.FADED_TEXT,
                fontWeight: fontWeight.medium,
              }}
            >
              {title}
            </Typography>
          </Box>
          {/* Value */}
          <Box>
            <Typography
              sx={{
                fontSize: 24,
                fontWeight: fontWeight.medium,
              }}
            >
              {value}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  )
}
