export interface User {
  email?: string
  name?: string
  user_role?: string
}

export interface AuthState {
  auth_token: string | null
  expires_in: number | null
  user: User | null
}

export const initialState: AuthState = {
  user: localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null,
  auth_token: localStorage.getItem('auth_token') ?? '',
  expires_in: localStorage.getItem('expires_in')
    ? parseInt(localStorage.getItem('expires_in'), 10)
    : 0,
}
