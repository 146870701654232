import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  Drawer,
  Typography,
  DrawerProps,
} from '@mui/material'
import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material/'
import { ReactComponent as BSLogo } from 'assets/Logo/BS-white-logo.svg'
import { COLORS } from 'styles'
import { CRM } from 'helpers/Constants/path'
import { useLocation } from 'react-router-dom'
import { IconButton } from 'components/Forms/Button'
import { useCRMNavigate } from 'helpers/Hooks'
import sidebarLinks from './SidebarLinks'
import sidebarStyles from './Sidebar.style'

export interface SidebarProps extends DrawerProps {
  onClose: () => void
}

function Sidebar({ open, onClose }: SidebarProps) {
  const { pathname } = useLocation()
  const navigate = useCRMNavigate()

  const isCurrentPath = (path: string) =>
    path === pathname || (path !== CRM && pathname.startsWith(path))

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={
        [
          sidebarStyles.drawer,
          open && sidebarStyles.drawerOpen,
          !open && sidebarStyles.drawerClose,
        ] as SxProps
      }
    >
      <Box sx={sidebarStyles.drawerHeader}>
        <BSLogo className="bs-logo" />
        <IconButton onClick={onClose}>
          <ChevronLeftIcon sx={{ color: COLORS.WHITE }} />
        </IconButton>
      </Box>

      <Divider />

      <List sx={sidebarStyles.sidebarItemWrapper}>
        {sidebarLinks.map((link) => {
          const isActive = isCurrentPath(CRM + link.to)
          return (
            <ListItem
              key={link.name}
              sx={sidebarStyles.sidebarItem}
              disablePadding
            >
              <ListItemButton
                onClick={() => {
                  navigate(link.to)
                }}
                sx={
                  [
                    sidebarStyles.sidebarItemButton,
                    open && sidebarStyles.sidebarItemButtonOpen,
                    isActive && sidebarStyles.sidebarItemButtonActive,
                  ] as SxProps
                }
              >
                <ListItemIcon sx={sidebarStyles.sidebarItemIconWrapper}>
                  <link.icon
                    sx={
                      [
                        sidebarStyles.sidebarItemIcon,
                        isActive && sidebarStyles.sidebarItemTextActive,
                      ] as SxProps
                    }
                  />
                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  <Typography
                    sx={
                      [
                        sidebarStyles.sidebarItemLink,
                        isActive && sidebarStyles.sidebarItemTextActive,
                      ] as SxProps
                    }
                  >
                    {link.name}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          )
        })}
      </List>
    </Drawer>
  )
}

export default Sidebar
