import { Box, InputAdornment, Paper } from '@mui/material'
import { useFormik } from 'formik'
import { Button, TextField } from 'components/Forms'
import { MailOutline } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { LOGIN_ROUTE } from 'helpers/Constants/path'
import { ReactComponent as ReactLogo } from 'assets/Logo/logo.svg'
import authenticationService from 'services/authentication.service'
import SnackbarUtils from 'components/UI/Toaster'
import {
  formFieldsConfig,
  FORM_FIELDS,
  validationSchema,
} from './ForgotPassword.schema'
import loginStyles from '../Login/Login.style'

interface ForgotPasswordObject {
  email: string
}

function ForgotPassword() {
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: formFieldsConfig.initialValues,
    validationSchema,
    validateOnChange: true,
    onSubmit: (values) => onSubmit(values),
    validateOnMount: true,
  })

  const onSubmit = async (data: ForgotPasswordObject) => {
    formik.setSubmitting(true)
    try {
      const response = await authenticationService.forgotPassword(data.email)
      if (response) {
        SnackbarUtils.success(response.message)
        return navigate(LOGIN_ROUTE)
      }
      formik.setSubmitting(false)
    } catch (error) {
      SnackbarUtils.error('There was issue processing your request!')
      formik.setSubmitting(false)
    }
  }

  const getFormikFields = (field: string) => ({
    value: formik.values[field],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
    error: formik.touched[field] && Boolean(formik.errors[field]),
    helperText: formik.touched[field] && formik.errors[field],
  })

  return (
    <Box sx={loginStyles.pageWrapper}>
      <Paper sx={loginStyles.loginSection}>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={loginStyles.logoContainer}>
            <ReactLogo className="bs-logo" />
          </Box>

          <Box sx={loginStyles.inputsWrapper}>
            <Box sx={loginStyles.inputWrapper}>
              <TextField
                {...formFieldsConfig.email}
                {...getFormikFields(FORM_FIELDS.email)}
                size="small"
                endAdornment={
                  <InputAdornment position="end">
                    <MailOutline />
                  </InputAdornment>
                }
                fullWidth
              />
            </Box>

            <Box sx={loginStyles.submitContainer}>
              <Button
                type="submit"
                fullWidth
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {LABEL.submitButtonTitle}
              </Button>
            </Box>
          </Box>
        </form>
      </Paper>
    </Box>
  )
}

const LABEL = {
  submitButtonTitle: 'Submit',
}

export default ForgotPassword
