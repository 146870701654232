import { selectAuthToken } from 'redux/Auth/auth.selectors'
import { useAppSelector } from 'redux/hooks'
import { useNavigate } from 'react-router-dom'
import { CRM } from 'helpers/Constants/path'
import { useEffect } from 'react'

const PublicRoutes = (props) => {
  const navigate = useNavigate()
  const authToken = useAppSelector(selectAuthToken)
  useEffect(() => {
    if (authToken) {
      //  authorised so return component
      navigate(CRM, { replace: true })
    }
  }, [authToken, navigate])
  // not logged in
  return props.children
}

export default PublicRoutes
