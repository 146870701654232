import { Box, InputAdornment, SxProps } from '@mui/material'
import { Button, TextField } from 'components/Forms'
import {
  Card,
  Chip,
  PageTitle,
  PaginationData,
  Table,
  TableHeader,
} from 'components/UI'
import {
  Search as SearchIcon,
  Tune as FilterIcon,
  Clear as ClearIcon,
  Visibility as ViewIcon,
  CloudDownload as CloudDownloadIcon,
  ConfirmationNumber as ConfirmationNumberIcon,
  AirplaneTicket as AirplaneTicketIcon,
  Apartment as ApartmentIcon,
  Shield as ShieldIcon,
} from '@mui/icons-material'
import { useCallback, useEffect, useState } from 'react'
import { BOOKING_STATUS_COLOR, DOMAIN } from 'helpers/Constants'
import { bookingsService } from 'services'
import { useDebounce } from 'use-debounce'
import RightSidebar from 'components/Navigation/Sidebar/RightSidebar'
import { VIEW_SUBPATH } from 'helpers/Constants/path'
import { tableActionIconStyles } from 'styles'
import {
  NavigateFunction,
  useOrderNavigate,
  CsvDownloader,
} from 'helpers/Hooks'
import { currencyFormatter } from 'helpers/Methods/utils'

export default function OrderList() {
  const [orders, setOrders] = useState([])
  const [searchKeyword, setSearchKeyword] = useState('')
  const [debouncedQuery] = useDebounce(searchKeyword, 500)
  const [pagination, setPagination] = useState<PaginationData>({
    count: 0,
    page: 0,
    rowsPerPage: 10,
  })

  const [isFiltersOpen, setFiltersOpen] = useState(false)
  const navigate = useOrderNavigate()

  const fetchOrders = useCallback(async () => {
    const response = await bookingsService.bookings({
      params: {
        rowsPerPage: 10,
        page: pagination.page,
        search: debouncedQuery,
      },
    })
    setOrders(response.data)
    setPagination({
      page: pagination.page,
      count: response.total,
      rowsPerPage: parseInt(response.perPage, 10),
    })
  }, [debouncedQuery, pagination.page])

  const toggleFilters = useCallback(() => {
    setFiltersOpen(!isFiltersOpen)
  }, [isFiltersOpen])

  const exportOrders = async () => {
    const response = await bookingsService.exportBookings()
    CsvDownloader(response.columns, response.data, 'Orders.csv')
  }

  useEffect(() => {
    fetchOrders()
  }, [fetchOrders, pagination.page, debouncedQuery])

  return (
    <Box>
      <Box>
        <PageTitle>{CONTENT_LABELS.pageTitle}</PageTitle>
      </Box>

      <Card>
        <Box sx={ordersStyles.titleWrapper}>
          <Box sx={ordersStyles.searchWrapper}>
            <TextField
              placeholder="Search Orders"
              value={searchKeyword}
              onChange={(e) => {
                setSearchKeyword(e.target.value)
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              endAdornment={
                searchKeyword && (
                  <InputAdornment position="end">
                    <ClearIcon
                      onClick={() => setSearchKeyword('')}
                      sx={{ cursor: 'pointer' }}
                    />
                  </InputAdornment>
                )
              }
              fullWidth
            />
          </Box>

          <Box sx={ordersStyles.toolsWrapper}>
            <Button
              variant="outlined"
              sx={ordersStyles.button}
              startIcon={<FilterIcon />}
              onClick={toggleFilters}
            >
              {CONTENT_LABELS.filterButton}
            </Button>

            {/* <Button sx={ordersStyles.button}>{CONTENT_LABELS.addButton}</Button> */}
            <Button
              sx={ordersStyles.button}
              startIcon={<CloudDownloadIcon />}
              onClick={exportOrders}
            >
              {CONTENT_LABELS.downloadCSV}
            </Button>
          </Box>
        </Box>

        <Box sx={ordersStyles.tableWrapper}>
          <Table
            columns={tableHeaders(navigate)}
            data={orders}
            pagination={{
              ...pagination,
              onPageChange: (e, page) => {
                setPagination({
                  ...pagination,
                  page,
                })
              },
            }}
          />
        </Box>
      </Card>

      <RightSidebar
        open={isFiltersOpen}
        onClose={toggleFilters}
        headerText={CONTENT_LABELS.filterModalHeader}
        footerActions={[
          {
            label: 'Save',
            onClick: toggleFilters,
          },
        ]}
      >
        Some Data here
      </RightSidebar>
    </Box>
  )
}

interface OrdersStylesType {
  titleWrapper: SxProps
  searchWrapper: SxProps
  toolsWrapper: SxProps
  tableWrapper: SxProps
  button: SxProps
}

const ordersStyles: OrdersStylesType = {
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchWrapper: { width: 300 },
  toolsWrapper: {},
  tableWrapper: {
    mt: 2,
  },
  button: {
    mx: 0.5,
  },
}

const CONTENT_LABELS = {
  pageTitle: 'Orders Listing',
  filterButton: 'Filters',
  addButton: 'Add Order',
  downloadCSV: 'CSV',
  filterModalHeader: 'Filter Data',
}

const tableHeaders = (navigate: NavigateFunction): TableHeader[] => [
  { name: 'Order', key: 'bookingNum' },
  { name: 'Customer', key: 'name' },
  {
    name: 'Products',
    key: 'products',
    render: (value: {
      ticket: boolean | false
      flight: boolean | false
      hotel: boolean | false
      insurance: boolean | false
    }) => (
      <>
        {value?.ticket && <ConfirmationNumberIcon />}
        {value?.flight && <AirplaneTicketIcon />}
        {value?.hotel && <ApartmentIcon />}
        {value?.insurance && <ShieldIcon />}
      </>
    ),
  },
  {
    name: 'Status',
    key: 'status',
    render: (value) => (
      <Chip
        size="small"
        label={value}
        color={BOOKING_STATUS_COLOR[value]}
        borderLess
      />
    ),
  },
  {
    name: 'Total',
    key: 'price',
    render: (value) => currencyFormatter(value),
  },
  {
    name: 'Currency',
    key: 'currency',
  },
  {
    name: 'Domain',
    key: 'domain',
    render: (value) => DOMAIN[value].name,
  },
  { name: 'Date Added', key: 'created' },
  {
    name: 'Actions',
    key: 'id',
    render: (value) => (
      <ViewIcon
        sx={tableActionIconStyles}
        onClick={() => {
          navigate(`${VIEW_SUBPATH}?id=${value}`)
        }}
      />
    ),
  },
]
