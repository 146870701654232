import { useSnackbar, VariantType, WithSnackbarProps } from 'notistack'

let useSnackbarRef: WithSnackbarProps

export function SnackbarUtilsConfigurable() {
  useSnackbarRef = useSnackbar()
  return null
}

const SnackbarUtils = {
  success(msg: string) {
    this.toast(msg, 'success')
  },
  warning(msg: string) {
    this.toast(msg, 'warning')
  },
  info(msg: string) {
    this.toast(msg, 'info')
  },
  error(msg: string) {
    this.toast(msg, 'error')
  },
  toast(msg: string, variant: VariantType = 'default') {
    useSnackbarRef.enqueueSnackbar(msg, {
      variant,
      persist: false,
    })
  },
}

export default SnackbarUtils
