import { Box, InputAdornment, Paper, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { Button, TextField } from 'components/Forms'
import { MailOutline, Password } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import {
  FORGOT_PASSWORD_ROUTE,
  VERIFY_CODE_ROUTE,
} from 'helpers/Constants/path'
import { ReactComponent as ReactLogo } from 'assets/Logo/logo.svg'
import authenticationService from 'services/authentication.service'
import { LOGIN_SUCCESS } from 'helpers/Constants/messages'
import { Link } from 'components/Navigation'
import SnackbarUtils from 'components/UI/Toaster'
import loginStyles from './Login.style'
import { formFieldsConfig, FORM_FIELDS, validationSchema } from './Login.schema'

interface LoginObject {
  email: string
  password: string
}

function Login() {
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: formFieldsConfig.initialValues,
    validationSchema,
    validateOnChange: true,
    onSubmit: (values) => onSubmit(values),
    validateOnMount: true,
  })

  const onSubmit = async (data: LoginObject) => {
    formik.setSubmitting(true)
    try {
      const response = await authenticationService.login(
        data.email,
        data.password
      )
      if (response) {
        SnackbarUtils.success(LOGIN_SUCCESS)
        return navigate(`${VERIFY_CODE_ROUTE}/${response.code}`)
      }
      formik.setSubmitting(false)
    } catch (error) {
      SnackbarUtils.error('Error Processing your request!')
      formik.setSubmitting(false)
    }
  }

  const getFormikFields = (field: string) => ({
    value: formik.values[field],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
    error: formik.touched[field] && Boolean(formik.errors[field]),
    helperText: formik.touched[field] && formik.errors[field],
  })

  return (
    <Box sx={loginStyles.pageWrapper}>
      <Paper sx={loginStyles.loginSection}>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={loginStyles.logoContainer}>
            <ReactLogo className="bs-logo" />
          </Box>

          <Box sx={loginStyles.inputsWrapper}>
            <Box sx={loginStyles.inputWrapper}>
              <TextField
                {...formFieldsConfig.email}
                {...getFormikFields(FORM_FIELDS.email)}
                endAdornment={
                  <InputAdornment position="end">
                    <MailOutline />
                  </InputAdornment>
                }
                fullWidth
              />
            </Box>

            <Box sx={loginStyles.inputWrapper}>
              <TextField
                {...formFieldsConfig.password}
                {...getFormikFields(FORM_FIELDS.password)}
                endAdornment={
                  <InputAdornment position="end">
                    <Password />
                  </InputAdornment>
                }
                fullWidth
              />
            </Box>

            <Typography sx={loginStyles.forgotPasswordContainer}>
              <Link
                href={FORGOT_PASSWORD_ROUTE}
                sx={loginStyles.forgotPasswordLink}
              >
                {LABEL.forgotPassword}
              </Link>
            </Typography>

            <Box sx={loginStyles.submitContainer}>
              <Button
                type="submit"
                fullWidth
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {LABEL.submitButtonTitle}
              </Button>
            </Box>
          </Box>
        </form>
      </Paper>
    </Box>
  )
}

const LABEL = {
  forgotPassword: 'Forgot Password',
  submitButtonTitle: 'Login',
}

export default Login
