import { chartSettings } from './customerAnalytics.config'
import { CustomerAnalyticsState } from './customerAnalytics.types'

export const CHART_SETTINGS_KEY = 'customer-analytics-chart-settings'

export const initialState: CustomerAnalyticsState = {
  chartSettings: localStorage.getItem(CHART_SETTINGS_KEY)
    ? JSON.parse(localStorage.getItem(CHART_SETTINGS_KEY))
    : chartSettings,
}
