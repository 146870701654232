import { SxProps } from '@mui/material'
import { COLORS } from 'styles'

interface HeaderStylesTypes {
  headerSection: SxProps
}

const headerStyles: HeaderStylesTypes = {
  headerSection: {
    backgroundColor: COLORS.WHITE,
    boxShadow: 'rgba(82, 63, 105, 0.44) 0px 1px 20px 0px',
    display: 'flex',
    '.bs-logo-sm': {
      height: 'auto',
      // width: '38px',
      width: '150px',
      mr: '2px',
    },
  },
}

export default headerStyles
