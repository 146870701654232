import {
  Chip as MUIChip,
  ChipProps as MUIChipsProps,
  SxProps,
} from '@mui/material'
import { fontWeight } from 'styles/theme'

interface ChipProps extends MUIChipsProps {
  /**
   * removed border from chip
   * @default false
   */
  borderLess?: boolean
}

export default function Chip({
  variant = 'outlined',
  sx,
  borderLess = false,
  ...otherProps
}: ChipProps) {
  return (
    <MUIChip
      variant={variant}
      sx={
        [
          borderLess && { border: 0, fontWeight: fontWeight.medium },
          sx,
        ] as SxProps
      }
      {...otherProps}
    />
  )
}
