import { SxProps } from '@mui/material'

interface FormStylesTypes {
  loginSection: SxProps
  logoContainer: SxProps
  submitContainer: SxProps
  pageWrapper: SxProps
  inputsWrapper: SxProps
  inputWrapper: SxProps
  forgotPasswordContainer: SxProps
  forgotPasswordLink: SxProps
}

const loginStyles: FormStylesTypes = {
  pageWrapper: {
    height: '100vh',
    background: '#a4a7b924',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginSection: {
    display: 'flex',
    justifyContent: 'center',
    width: '550px',
    maxWidth: '100vw',
    px: 5,
    py: 4,
    borderRadius: 1.5,
    boxShadow: '0px 0px 20px 0px rgba(76, 87, 125, 0.02)',
    form: {
      width: 'inherit',
    },
  },
  logoContainer: {
    textAlign: 'center',
    py: 2,
    '.bs-logo': {
      height: 'auto',
      width: '150px',
    },
  },
  inputsWrapper: {
    py: 2,
  },
  inputWrapper: {
    py: 2,
  },
  forgotPasswordContainer: {
    textAlign: 'right',
    mb: 3,
  },
  forgotPasswordLink: {
    color: 'primary.main',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  submitContainer: {
    textAlign: 'center',
    mt: 2,
  },
}

export default loginStyles
