import {
  Dashboard as DashboardIcon,
  Analytics as AnalyticsIcon,
  PieChartOutlineRounded as PieChartIcon,
  LocalGroceryStore as ShopIcon,
  Newspaper as NewspaperIcon,
  Group as GroupIcon,
} from '@mui/icons-material'
import {
  HOME,
  ORDERS,
  ORDERS_WRAPPER,
  CUSTOMER,
  CUSTOMER_WRAPPER,
  USERS,
  USERS_WRAPPER,
  SUBSCRIBERS,
  CRM_USERS_WRAPPER,
  CRM_USERS,
} from 'helpers/Constants/path'
import Dashboard from 'pages/Dashboard'
import Orders from 'pages/Orders'
import NewsLetterSubscribers from 'pages/NewsLetterSubscribers'
import Users from 'pages/Users/Users' // todo
import CustomerAnalytics from 'pages/CustomerAnalytics'

const sidebarLinks = [
  {
    name: 'Dashboard',
    icon: DashboardIcon,
    to: HOME,
    component: Dashboard,
  },
  {
    name: 'Orders',
    icon: ShopIcon,
    to: ORDERS,
    path: ORDERS_WRAPPER,
    component: Orders,
  },

  {
    name: 'Customer Analytics',
    icon: PieChartIcon,
    to: CUSTOMER,
    path: CUSTOMER_WRAPPER,
    component: CustomerAnalytics,
  },

  {
    name: 'User Analytics',
    icon: AnalyticsIcon,
    to: USERS,
    path: USERS_WRAPPER,
    component: Users,
  },

  {
    name: 'Subscribers',
    icon: NewspaperIcon,
    to: SUBSCRIBERS,
    component: NewsLetterSubscribers,
  },

  {
    name: 'CRM User',
    icon: GroupIcon,
    to: CRM_USERS,
    path: CRM_USERS_WRAPPER,
    component: Users,
  },

  // {
  //   name: 'Contacts',
  //   icon: ContactsIcon,
  //   to: '/contacts',
  //   component: Users,
  // },
  // {
  //   name: 'CRM Users',
  //   icon: ManageAccountsOutlinedIcon,
  //   to: '/crm-users',
  //   component: Users,
  // },
]

export default sidebarLinks
