import { Typography, TypographyProps } from '@mui/material'
import { COLORS } from 'styles'
import { fontWeight } from 'styles/theme'

export interface PageTitleProps extends TypographyProps {}

export default function PageTitle({ sx, ...otherProps }: PageTitleProps) {
  return (
    <Typography
      variant="h1"
      sx={{
        color: COLORS.SLATE_GREY,
        fontSize: 18,
        fontWeight: fontWeight.bold,
        mt: 0,
        mb: 2,
        ...sx,
      }}
      {...otherProps}
    />
  )
}
