import {
  LOGIN_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  VERIFY_CODE_ROUTE,
  HOME,
  CRM,
} from 'helpers/Constants/path'
import { Login, ForgotPassword, VerifyCode } from 'pages/Auth'
import { Routes, Route, Navigate } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { PrivateRoutes, PublicRoutes } from 'routes'
import MainWrapper from 'components/Core/MainWrapper'
import sidebarLinks from 'components/Navigation/Sidebar/SidebarLinks'
import { SnackbarUtilsConfigurable } from 'components/UI/Toaster'

export default function AppRouter() {
  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={3000}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <Routes>
        <Route
          path={`${CRM}/*`}
          element={
            <PrivateRoutes>
              <MainWrapper>
                <Routes>
                  {sidebarLinks.map((link) => (
                    <Route
                      path={link.path ?? link.to}
                      element={<link.component />}
                      key={link.to}
                    />
                  ))}
                </Routes>
              </MainWrapper>
            </PrivateRoutes>
          }
        />

        <Route
          path={`${HOME}*`}
          element={
            <PublicRoutes>
              <Routes>
                <Route path={LOGIN_ROUTE} element={<Login />} />
                <Route
                  path={`${VERIFY_CODE_ROUTE}/:code`}
                  element={<VerifyCode />}
                />
                <Route
                  path={FORGOT_PASSWORD_ROUTE}
                  element={<ForgotPassword />}
                />
                <Route path="*" element={<Navigate to={LOGIN_ROUTE} />} />
              </Routes>
            </PublicRoutes>
          }
        />
      </Routes>
      <SnackbarUtilsConfigurable />
    </SnackbarProvider>
  )
}
