import { ColumnChart, ChartWidgetWrapper } from 'components/UI'
import { ChartWidget } from './ChartWidgetWrapper'

export default function ColumnChartWidget({
  title,
  ...otherProps
}: ChartWidget) {
  return (
    <ChartWidgetWrapper title={title}>
      <ColumnChart
        {...otherProps}
        chart={{
          height: '300px',
        }}
        dataLabels
      />
    </ChartWidgetWrapper>
  )
}
