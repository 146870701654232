import { CRM, SUBSCRIBERS, ORDERS, USERS, CRM_USERS } from 'helpers/Constants/path'
import { NavigateOptions, To, useNavigate } from 'react-router-dom'

export type NavigateFunction = (to: To, options?: NavigateOptions) => void

/**
 * This function is base wrapper for use navigate
 * @param prefixURL base url
 * @returns navigate from `react-router`
 */
export function useCoreNavigate(prefixURL = '') {
  const navigate = useNavigate()

  const redirect: NavigateFunction = (to: To, options?: NavigateOptions) => {
    navigate(prefixURL + to, options)
  }
  return redirect
}

/**
 * this hook has base url set to crm, useful for base private routes
 * @returns NavigateFunction
 */
export const useCRMNavigate = () => useCoreNavigate(CRM)

/**
 * this hook has base url set to crm
 * @returns NavigateFunction
 */
export const useOrderNavigate = () => useCoreNavigate(CRM + ORDERS)

/**
 * this hook has base url set to crm
 * @returns NavigateFunction
 */
export const useNewsletterNavigate = () => useCoreNavigate(CRM + SUBSCRIBERS)

/**
 * this hook has base url set to crm
 * @returns NavigateFunction
 */
export const useUserNavigate = () => useCoreNavigate(CRM + USERS)

/**
 * this hook has base url set to crm
 * @returns NavigateFunction
 */
export const useCrmUsersNavigate = () => useCoreNavigate(CRM + CRM_USERS)
