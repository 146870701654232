import { Box, SxProps, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { COLORS } from 'styles'
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress'
import { fontWeight } from 'styles/theme'
import { WidgetCard } from 'components/UI'

interface TopSearchChartProps {
  titleText: string
  data: QueryDataType[]
  setPopularEventsDateRange?: (range) => void
  popularEventsDateRange?: any
}

export default function TopQueries({
  titleText,
  data,
  setPopularEventsDateRange,
  popularEventsDateRange,
}: TopSearchChartProps) {
  const [queries, setQueries] = useState<QueryDataType[]>([])

  useEffect(() => {
    if (data) {
      setTimeout(() => {
        const maxValue = data[0].count
        setQueries(
          data.map((query) => ({
            ...query,
            percentageValue: (100 * query.count) / maxValue,
          }))
        )
      }, 300)
    }
  }, [data])

  return (
    <WidgetCard
      title={titleText}
      // subTitle="Counts in thousands"
      fullHeight
      dateRangeProps={{
        value: popularEventsDateRange,
        onChange: (range) => {
          setPopularEventsDateRange(range)
        },
        disableFutureDates: true,
      }}
      enableDateRange
    >
      <Box sx={topQueriesStyle.listItemsWrapper}>
        <Box sx={topQueriesStyle.listItem}>
          <Box sx={topQueriesStyle.itemLabelWrapper}>
            <Typography sx={topQueriesStyle.itemHeader}>Events</Typography>
          </Box>
          <Box sx={topQueriesStyle.itemResultWrapper}>
            <Typography sx={topQueriesStyle.itemHeader}>Count</Typography>
          </Box>
        </Box>
        {queries.map((query) => (
          <Box sx={topQueriesStyle.listItem} key={query.id}>
            <Box sx={topQueriesStyle.itemLabelWrapper}>
              <Typography sx={topQueriesStyle.itemLabel}>
                {query.keyword}
              </Typography>
            </Box>
            <Box sx={topQueriesStyle.itemResultWrapper}>
              <Box sx={topQueriesStyle.itemValueWrapper}>
                <Typography sx={topQueriesStyle.itemValue}>
                  {query.count}
                </Typography>
              </Box>

              <Box sx={topQueriesStyle.itemProgressWrapper}>
                <Progress value={query.percentageValue} />
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </WidgetCard>
  )
}

interface ProgressProps {
  /**
   * number specified in percentage value
   */
  value: number
}

function Progress({ value }: ProgressProps) {
  return (
    <LinearProgress
      sx={topQueriesStyle.progress}
      variant="determinate"
      value={value}
    />
  )
}

interface TopQueriesStyleType {
  listItemsWrapper: SxProps
  listItem: SxProps
  itemHeader: SxProps
  itemLabelWrapper: SxProps
  itemLabel: SxProps
  itemResultWrapper: SxProps
  itemValueWrapper: SxProps
  itemValue: SxProps
  itemProgressWrapper: SxProps
  progress: SxProps
}

const SIZES = {
  LABEL: 50,
  PROGRESS: 150,
}

const topQueriesStyle: TopQueriesStyleType = {
  listItemsWrapper: {
    my: 3,
  },
  listItem: {
    display: 'flex',
    my: 2,
  },
  itemHeader: {
    fontSize: 14,
    color: COLORS.FADED_TEXT,
    fontWeight: fontWeight.medium,
  },
  itemLabelWrapper: {
    width: `calc(100% - ${SIZES.LABEL}px - ${SIZES.PROGRESS}px)`,
  },
  itemLabel: {
    fontSize: 14,
    fontWeight: fontWeight.bold,
    color: COLORS.GUNMETAL_BLUE,
    pr: 1,
  },
  itemResultWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  itemValueWrapper: {
    width: SIZES.LABEL,
    px: 1,
  },
  itemValue: {
    fontSize: 14,
    fontWeight: fontWeight.medium,
  },
  itemProgressWrapper: {
    width: SIZES.PROGRESS,
  },
  progress: {
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: 'transparent',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: COLORS.PRIMARY,
    },
  },
}

type QueryDataType = {
  id: number
  keyword: string
  count: number
  percentageValue?: number
}
