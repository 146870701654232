/**
 * This function merges two object with same keys
 * @param obj1 Object
 * @param obj2 Object
 */
export function mergeObject(obj1, obj2) {
  const obj1Keys = Object.keys(obj1)
  const obj2Keys = Object.keys(obj2)

  const mergedObject = {}

  obj1Keys.forEach((key) => {
    const obj1Data = obj1[key]
    const obj2Data = obj2[key]

    if (Array.isArray(obj1Data))
      mergedObject[key] = [...obj1Data, ...(obj2Data ?? [])]
    else if (typeof obj1Data === 'object')
      mergedObject[key] = {
        ...obj1Data,
        ...(obj2Data ?? {}),
      }
    else mergedObject[key] = obj2Data ?? obj1Data
  })

  obj2Keys.forEach((key) => {
    if (!mergedObject[key]) mergedObject[key] = obj2[key]
  })

  return mergedObject
}
