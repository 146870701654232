const BASE_COLORS = {
  WHITE: '#FFF',

  BLACK: '#000',
  DARK_BLUE: '#122a43',
  GUNMETAL_BLUE: '#181c32',
  BUBBLES_BLUE: '#e3f3fd',
  HIGHLIGHT_BLUE: '#009ef7',
  TRUE_BLUE: '#007CD6',
  PICTON_BLUE: '#3aabf8',

  LIGHT_GREY: '#f9f9f9',
  BRIGHT_GRAY: '#EFEFEF',
  SLATE_GREY: '#262f4f',
  CADET_BLUE: '#B5B5C3',
  GRANITE_GRAY: '#645E5E',
  ROMAN_SILVER: '#7E8299',

  EMERALD_GREEN: '#5ABE87',
  PARADISE_PINK: '#f1416c',
  SPANISH_CRIMSON: '#DC1E53',
  WATERMELON: '#f66287',
  QUEEN_PINK: '#e3cadc',
  BALL_BLUE: '#1fb5c9',
  DEEP_SAFFRON: '#f8a92f',
  MEDIUM_PURPLE: '#866fd8',
  TUMBLE_WEED: '#e1a87d',
  DARK_TERRA_COTTA: '#cb4967',
}

const COLORS = {
  ...BASE_COLORS,
  PRIMARY: BASE_COLORS.HIGHLIGHT_BLUE,
  PRIMARY_DARK: BASE_COLORS.TRUE_BLUE,
  PRIMARY_LIGHT: BASE_COLORS.PICTON_BLUE,
  PRIMARY_SUPER_LIGHT: BASE_COLORS.BUBBLES_BLUE,

  SECONDARY: BASE_COLORS.PARADISE_PINK,
  SECONDARY_DARK: BASE_COLORS.SPANISH_CRIMSON,
  SECONDARY_LIGHT: BASE_COLORS.WATERMELON,

  TERTIARY: BASE_COLORS.EMERALD_GREEN,
  DARK_BACKGROUND: BASE_COLORS.GUNMETAL_BLUE,
  FADED_TEXT: BASE_COLORS.GRANITE_GRAY,
  LIGHT_TEXT: BASE_COLORS.CADET_BLUE,
  SEMI_DARK_TEXT: BASE_COLORS.ROMAN_SILVER,
}

export const CHART_COLORS = [
  COLORS.PRIMARY,
  COLORS.SECONDARY,
  COLORS.TERTIARY,
  COLORS.BALL_BLUE,
  COLORS.MEDIUM_PURPLE,
  COLORS.QUEEN_PINK,
  COLORS.TUMBLE_WEED,
  COLORS.DARK_TERRA_COTTA,
  COLORS.DEEP_SAFFRON,
  COLORS.CADET_BLUE,
  COLORS.GRANITE_GRAY,
]

export default COLORS
