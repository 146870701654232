import { SxProps } from '@mui/material'

interface FormStylesTypes {
  verifyCodeSection: SxProps;
  titleContainer: SxProps;
  submitContainer: SxProps;
  pageWrapper: SxProps;
  forgotPasswordContainer: SxProps;
}

const VerifyCodeFormStyles: FormStylesTypes = {
  pageWrapper: {
    height: '100vh',
    background: '#a4a7b924',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  verifyCodeSection: {
    display: 'flex',
    justifyContent: 'center',
    pt: 2,
    px: '5rem',
    pb: '4rem',
    width: '550px',
    maxWidth: '100vw',
    borderRadius: '10px',
    boxShadow: '0px 0px 20px 0px rgba(76, 87, 125, 0.02)',
    form: {
      width: 'inherit',
    },
  },
  titleContainer: {
    textAlign: 'center',
    '.bs-logo': {
      height: 'auto',
      width: '150px',
    },
  },

  forgotPasswordContainer: {
    textAlign: 'right',
    a: {
      color: 'primary.main',
      textDecoration: 'none',
      py: '6px',
    },
  },
  submitContainer: {
    pt: '15px',
    display: 'flex',
    justifyContent: 'space-between',
  },
}

export default VerifyCodeFormStyles