import Papa from 'papaparse'

export function CsvDownloader(csvHeaders,data,fileName) {
  // Convert the data to CSV format using 'papaparse'
  const csvData = Papa.unparse({
    fields: csvHeaders,
    data,
  })

  const blob = new Blob([csvData], { type: 'text/csv' })
  // Create a URL from the Blob
  const url = URL.createObjectURL(blob)

  // Create a temporary anchor element
  const anchor = document.createElement('a')
  anchor.href = url
  anchor.download = fileName

  // Programmatically click the anchor to trigger the download
  anchor.click()

  // Clean up the URL and anchor
  URL.revokeObjectURL(url)
  anchor.remove()
}