import { CHART_TYPES } from 'helpers/Constants'
import { ChartSettingsType } from './customerAnalytics.types'

export const chartSettings: ChartSettingsType[] = [
  {
    props: {
      title: 'Days Between Booking and Event',
      xAxisTitle: 'Days',
      yAxisTitle: 'Bookings',
    },
    dataKey: 'bookingTimeDiff',
    type: CHART_TYPES.COLUMN,
    visible: true,
  },
  {
    props: {
      title: 'Revenue Per Product',
      xAxisTitle: 'Products',
      yAxisTitle: 'Revenue',
      isRevenue: true,
    },
    dataKey: 'revenuePerProduct',
    type: CHART_TYPES.COLUMN,
    visible: true,
  },
  {
    props: {
      title: 'Airline Revenue',
      xAxisTitle: 'Airlines',
      yAxisTitle: 'Revenue',
      isRevenue: true,
    },
    dataKey: 'airlineRevenue',
    type: CHART_TYPES.COLUMN,
    visible: true,
  },
  {
    props: {
      title: 'Passenger Age',
      xAxisTitle: 'Age',
      yAxisTitle: 'Users',
    },
    dataKey: 'passengerAge',
    type: CHART_TYPES.COLUMN,
    visible: true,
  },
  {
    props: {
      title: 'Hotel Ratings',
      xAxisTitle: 'Rating',
      yAxisTitle: 'Count',
    },
    dataKey: 'hotelRatings',
    type: CHART_TYPES.COLUMN,
    visible: true,
  },
  {
    props: {
      title: 'Room Types',
      xAxisTitle: 'Type',
      yAxisTitle: 'Bookings',
    },
    dataKey: 'hotelRoomTypes',
    type: CHART_TYPES.COLUMN,
    visible: true,
  },
  {
    props: {
      title: 'Top Events',
      keywordTitle: 'Events',
      countTitle: 'Bookings',
    },
    dataKey: 'topEvents',
    type: CHART_TYPES.TOP_X_LIST,
    visible: true,
  },
  {
    props: {
      title: 'Top Destination',
      keywordTitle: 'Places',
      countTitle: 'Events',
    },
    dataKey: 'topDestination',
    type: CHART_TYPES.TOP_X_LIST,
    visible: true,
  },
  {
    props: {
      title: 'Top Departure Cities',
      keywordTitle: 'Places',
      countTitle: 'People',
    },
    dataKey: 'topDepartureCities',
    type: CHART_TYPES.TOP_X_LIST,
    visible: true,
  },
  {
    props: {
      title: 'Top Arrival Cities',
      keywordTitle: 'Places',
      countTitle: 'People',
    },
    dataKey: 'topArrivalCities',
    type: CHART_TYPES.TOP_X_LIST,
    visible: true,
  },
  {
    props: {
      title: 'Top Venues',
      keywordTitle: 'Venue',
      countTitle: 'People',
    },
    dataKey: 'topVenues',
    type: CHART_TYPES.TOP_X_LIST,
    visible: true,
  },
  {
    props: {
      title: 'Top 10 State/Province Billing Address',
      keywordTitle: 'State',
      countTitle: 'People',
    },
    dataKey: 'topStates',
    type: CHART_TYPES.TOP_X_LIST,
    visible: true,
  },
  {
    props: {
      title: 'Ticket Type',
      xAxisTitle: 'Types',
      yAxisTitle: 'Bookings',
    },
    dataKey: 'ticketType',
    type: CHART_TYPES.PIE,
    visible: true,
  },
  {
    props: {
      title: 'Passenger Gender',
      xAxisTitle: 'Genders',
      yAxisTitle: 'Count',
    },
    dataKey: 'passengerGender',
    type: CHART_TYPES.PIE,
    visible: true,
  },
  {
    props: {
      title: 'Class Of Service',
      xAxisTitle: 'Class',
      yAxisTitle: 'Bookings',
    },
    dataKey: 'classOfService',
    type: CHART_TYPES.PIE,
    visible: true,
  },
  {
    props: {
      title: 'Hotel Cancellation',
      xAxisTitle: '',
      yAxisTitle: 'Count',
    },
    dataKey: 'hotelCancellation',
    type: CHART_TYPES.PIE,
    visible: true,
  },
  {
    props: {
      title: 'Currency',
      xAxisTitle: 'Currency',
      yAxisTitle: 'Count',
    },
    dataKey: 'currency',
    type: CHART_TYPES.PIE,
    visible: true,
  },
]
