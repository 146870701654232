/* eslint-disable import/no-extraneous-dependencies */
import { BrowserRouter } from 'react-router-dom'
import AppRouter from 'components/Core/AppRouter'
import { ThemeProvider } from '@mui/material'
import theme from 'styles/theme'

import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css'
import 'react-calendar/dist/Calendar.css'

import './styles/global.css'
import '@fontsource/inter/300.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'

export default function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <AppRouter />
      </ThemeProvider>
    </BrowserRouter>
  )
}
