import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './app.state'
import AppActions from './app.action'

export const appSlice = createSlice({
  name: 'app',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
  },

  extraReducers: (builder) => {
    builder.addCase(AppActions.updateAppSidebarShow, (state, action) => {
      state.sideBarShow = action.payload.sideBarShow
    })
  },
})

export default appSlice.reducer
