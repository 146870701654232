import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import authReducer from './Auth/auth.reducer'
import appReducer from './App/app.reducer'
import customerAnalyticsReducer from './CustomerAnalytics/customerAnalytics.reducer'

export const store = configureStore({
  reducer: {
    authUser: authReducer,
    appState: appReducer,
    customerAnalytics: customerAnalyticsReducer,
  },
  devTools: {
    name: 'BookSeats-CRM',
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
