import { createTheme } from '@mui/material/styles'
import COLORS from './colors'

export const fontWeight = {
  /**
   * 300
   */
  light: 'fontWeightLight',
  /**
   * 400
   */
  regular: 'fontWeightRegular',
  /**
   * 500
   */
  medium: 'fontWeightMedium',
  /**
   * 600
   */
  bold: 'fontWeightBold',
  /**
   * 700
   */
  bolder: 'fontWeightBolder',
  /**
   * 800
   */
  boldest: 'fontWeightBoldest',
}

export const fontFamily = ['Inter', 'Helvetica', 'Arial', 'sans-serif'].join(
  ','
)

export const cardShadow = '0px 0px 20px 0px rgba(76, 87, 125, 0.1)'

const fontWeightValues = {
  [fontWeight.light]: 300,
  [fontWeight.regular]: 400,
  [fontWeight.medium]: 500,
  [fontWeight.bold]: 600,
  [fontWeight.bolder]: 700,
  [fontWeight.boldest]: 800,
}

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.PRIMARY,
      light: COLORS.PRIMARY_LIGHT,
      dark: COLORS.PRIMARY_DARK,
      contrastText: COLORS.WHITE,
    },
    secondary: {
      main: COLORS.SECONDARY,
      light: COLORS.SECONDARY_LIGHT,
      dark: COLORS.SECONDARY_DARK,
      contrastText: COLORS.WHITE,
    },
  },
  typography: {
    fontFamily,
    ...fontWeightValues,
  },
})

export default theme
