import { Box } from '@mui/material'
import { ChartOptionsProps, WidgetCard } from 'components/UI'
import { ReactNode } from 'react'

export interface ChartWidgetWrapperProps {
  /**
   * Chart title
   */
  title: string

  /**
   * react element
   */
  children?: ReactNode
}
export interface ChartWidget
  extends ChartOptionsProps,
    ChartWidgetWrapperProps {}

export default function ChartWidgetWrapper({
  title,
  children,
}: ChartWidgetWrapperProps) {
  return (
    <WidgetCard title={title} fullHeight>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          my: 0,
          mt: 2,
        }}
      >
        <Box
          sx={{
            width: '100%',
          }}
        >
          {children}
        </Box>
      </Box>
    </WidgetCard>
  )
}
