import { PropsWithChildren } from 'react'
import Header from 'components/Navigation/Header'
import { Box, CssBaseline } from '@mui/material'
import Sidebar from 'components/Navigation/Sidebar'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { selectAppSidebarShow } from 'redux/App/app.selectors'
import AppActions from 'redux/App/app.action'
import wrapperStyles from './MainWrapper.style'

export default function MainWrapper({ children }: PropsWithChildren) {
  const dispatch = useAppDispatch()
  const open = useAppSelector(selectAppSidebarShow)

  const handleDrawerOpen = () => {
    dispatch(AppActions.updateAppSidebarShow({ sideBarShow: true }))
  }

  const onClose = () => {
    dispatch(AppActions.updateAppSidebarShow({ sideBarShow: false }))
  }

  return (
    <Box sx={wrapperStyles.wrapper}>
      <CssBaseline />
      <Header open={open} handleDrawerOpen={handleDrawerOpen} />
      <Sidebar open={open} onClose={onClose} />
      <Box component="main" sx={wrapperStyles.contentWrapper}>
        {children}
      </Box>
    </Box>
  )
}
