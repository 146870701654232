import { SHOULD_MOCK_APIS } from 'helpers/Constants'
import {
  userListResponse,
  userDetailsResponse,
  exportUsersCSVResponse,
} from 'assets/mocks'
import apiHandler from 'helpers/ApiHandler'

const users = (data = {}) =>
  SHOULD_MOCK_APIS
    ? apiHandler.mockApi(userListResponse)
    : apiHandler.getRequest('users', data)

const userDetails = (data = {}) =>
  SHOULD_MOCK_APIS
    ? apiHandler.mockApi(userDetailsResponse)
    : apiHandler.getRequest('userDetails', data)

const exportUsers = (data = {}) =>
  SHOULD_MOCK_APIS
    ? apiHandler.mockApi(exportUsersCSVResponse)
    : apiHandler.getRequest('exportUsers', data)

const usersService = {
  users,
  userDetails,
  exportUsers,
}

export default usersService
