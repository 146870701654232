import { Box, InputAdornment, SxProps } from '@mui/material'
import { Button, TextField } from 'components/Forms'
import {
  Card,
  PageTitle,
  PaginationData,
  Table,
  TableHeader,
} from 'components/UI'
import {
  Search as SearchIcon,
  Tune as FilterIcon,
  Clear as ClearIcon,
  Visibility as ViewIcon,
  CloudDownload as CloudDownloadIcon,
} from '@mui/icons-material'
import { useCallback, useEffect, useState } from 'react'
import { usersService } from 'services'
import { useDebounce } from 'use-debounce'
import RightSidebar from 'components/Navigation/Sidebar/RightSidebar'
import { VIEW_SUBPATH } from 'helpers/Constants/path'
import { tableActionIconStyles } from 'styles'
import { NavigateFunction, useUserNavigate, CsvDownloader } from 'helpers/Hooks'

export default function UserList() {
  const [users, setUsers] = useState([])
  const [searchKeyword, setSearchKeyword] = useState('')
  const [debouncedQuery] = useDebounce(searchKeyword, 500)
  const [pagination, setPagination] = useState<PaginationData>({
    count: 0,
    page: 0,
    rowsPerPage: 10,
  })

  const [isFiltersOpen, setFiltersOpen] = useState(false)
  const navigate = useUserNavigate()

  const fetchUsers = useCallback(async () => {
    const response = await usersService.users({
      params: {
        rowsPerPage: 10,
        page: pagination.page,
        search: debouncedQuery,
      },
    })
    setUsers(response.data)
    setPagination({
      page: pagination.page,
      count: response.total,
      rowsPerPage: parseInt(response.perPage, 10),
    })
  }, [debouncedQuery, pagination.page])

  const exportUsers = async () => {
    const response = await usersService.exportUsers()
    CsvDownloader(response.columns, response.data,'Users.csv')
  }

  const toggleFilters = useCallback(() => {
    setFiltersOpen(!isFiltersOpen)
  }, [isFiltersOpen])

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers, pagination.page, debouncedQuery])

  return (
    <Box>
      <Box>
        <PageTitle>{CONTENT_LABELS.pageTitle}</PageTitle>
      </Box>

      <Card>
        <Box sx={usersStyles.titleWrapper}>
          <Box sx={usersStyles.searchWrapper}>
            <TextField
              placeholder="Search Users"
              value={searchKeyword}
              onChange={(e) => {
                setSearchKeyword(e.target.value)
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              endAdornment={
                searchKeyword && (
                  <InputAdornment position="end">
                    <ClearIcon
                      onClick={() => setSearchKeyword('')}
                      sx={{ cursor: 'pointer' }}
                    />
                  </InputAdornment>
                )
              }
              fullWidth
            />
          </Box>

          <Box sx={usersStyles.toolsWrapper}>
            <Button
              variant="outlined"
              sx={usersStyles.button}
              startIcon={<FilterIcon />}
              onClick={toggleFilters}
            >
              {CONTENT_LABELS.filterButton}
            </Button>
            {/* <Button sx={usersStyles.button}>{CONTENT_LABELS.addButton}</Button> */}
            <Button
              sx={usersStyles.button}
              startIcon={<CloudDownloadIcon />}
              onClick={exportUsers}
            >
              {CONTENT_LABELS.downloadCSV}
            </Button>
          </Box>
        </Box>

        <Box sx={usersStyles.tableWrapper}>
          <Table
            columns={tableHeaders(navigate)}
            data={users}
            pagination={{
              ...pagination,
              onPageChange: (e, page) => {
                setPagination({
                  ...pagination,
                  page,
                })
              },
            }}
          />
        </Box>
      </Card>

      <RightSidebar
        open={isFiltersOpen}
        onClose={toggleFilters}
        headerText={CONTENT_LABELS.filterModalHeader}
        footerActions={[
          {
            label: 'Save',
            onClick: toggleFilters,
          },
        ]}
      >
        Some Data here
      </RightSidebar>
    </Box>
  )
}

interface UsersStylesType {
  titleWrapper: SxProps
  searchWrapper: SxProps
  toolsWrapper: SxProps
  tableWrapper: SxProps
  button: SxProps
}

const usersStyles: UsersStylesType = {
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchWrapper: { width: 300 },
  toolsWrapper: {},
  tableWrapper: {
    mt: 2,
  },
  button: {
    mx: 0.5,
  },
}

const CONTENT_LABELS = {
  pageTitle: 'Users Listing',
  filterButton: 'Filters',
  addButton: 'Add Users',
  filterModalHeader: 'Filter Data',
  downloadCSV: 'CSV',
}

const tableHeaders = (navigate: NavigateFunction): TableHeader[] => [
  {
    name: 'Name',
    key: 'FirstName',
    render: (value, data) =>
      `${data?.TitleName} ${data?.FirstName} ${data?.MiddleName} ${data?.LastName}`,
  },
  { name: 'Phone', key: 'primaryPhone' },
  {
    name: 'Email',
    key: 'Email',
  },
  {
    name: 'DOB',
    key: 'DOB',
  },
  { name: 'Enroll Date', key: 'CreatedDate' },
  {
    name: 'Actions',
    key: 'ID',
    render: (value) => (
      <ViewIcon
        sx={tableActionIconStyles}
        onClick={() => {
          navigate(`${VIEW_SUBPATH}?id=${value}`)
        }}
      />
    ),
  },
]
