import { SxProps } from '@mui/material'
import ButtonMUI, { ButtonProps as MuiButtonProps } from '@mui/material/Button'

export interface ButtonProps extends MuiButtonProps {}

function Button({ sx, ...props }: ButtonProps) {
  return (
    <ButtonMUI
      sx={
        [
          {
            borderRadius: '5px',
            paddingY: 1,
            paddingX: 2,
          },
          sx,
        ] as SxProps
      }
      size="small"
      variant="contained"
      {...props}
    />
  )
}

export default Button
