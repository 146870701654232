import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './auth.state'
import authActions from './auth.action'

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
  },

  extraReducers: (builder) => {
    builder
      .addCase(authActions.updateAuthState, (state, action) => {
        localStorage.setItem('auth_token', action.payload.auth_token)
        localStorage.setItem('user', JSON.stringify(action.payload.user))
        localStorage.setItem('expires_in', action.payload.expires_in.toString())

        state.auth_token = action.payload.auth_token
        state.user = action.payload.user
        state.expires_in = action.payload.expires_in
      })
      .addCase(authActions.userLogout, (state) => {
        localStorage.removeItem('auth_token')
        localStorage.removeItem('user')
        localStorage.removeItem('expires_in')

        state.auth_token = ''
        state.user = null
        state.expires_in = 0
      })
  },
})

export default authSlice.reducer
