import { Box, InputAdornment, Paper } from '@mui/material'
import { useFormik } from 'formik'
import { Button, TextField } from 'components/Forms'
import { Pin } from '@mui/icons-material'
import { useNavigate, useParams } from 'react-router-dom'
import { CRM } from 'helpers/Constants/path'
import { ReactComponent as ReactLogo } from 'assets/Logo/logo.svg'
import authenticationService from 'services/authentication.service'
import authActions from 'redux/Auth/auth.action'
import { useAppDispatch } from 'redux/hooks'
import SnackbarUtils from 'components/UI/Toaster'
import { LOGIN_SUCCESS } from 'helpers/Constants/messages'
import VerifyCodeFormStyles from './VerifyCde.style'
import {
  formFieldsConfig,
  FORM_FIELDS,
  validationSchema,
} from './verifyCode.schema'

interface VerifyCodeObject {
  two_factor_code: number
}

function VerifyCode() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { code } = useParams()

  const formik = useFormik({
    initialValues: formFieldsConfig.initialValues,
    validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      submit(values)
    },
    validateOnMount: true,
  })

  const submit = async (data: VerifyCodeObject) => {
    formik.setSubmitting(true)
    try {
      const response = await authenticationService.verifyAuthentication(
        data.two_factor_code,
        code
      )
      if (response) {
        dispatch(authActions.updateAuthState(response))
        navigate(CRM)
      }
      formik.setSubmitting(false)
    } catch (error) {
      SnackbarUtils.error('Error Processing your request!')
      formik.setSubmitting(false)
    }
  }

  const resendAuthCode = async () => {
    formik.setSubmitting(true)
    try {
      const response = await authenticationService.resendAuthenticationCode(
        code
      )
      if (response) {
        SnackbarUtils.success(LOGIN_SUCCESS)
      }
      formik.setSubmitting(false)
    } catch (error) {
      SnackbarUtils.error('Error Processing your request!')
      formik.setSubmitting(false)
    }
  }

  const getFormikFields = (field: string) => ({
    value: formik.values[field],
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
    error: formik.touched[field] && Boolean(formik.errors[field]),
    helperText: formik.touched[field] && formik.errors[field],
  })

  return (
    <Box sx={VerifyCodeFormStyles.pageWrapper}>
      <Paper sx={VerifyCodeFormStyles.verifyCodeSection}>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={VerifyCodeFormStyles.titleContainer} py={1}>
            <ReactLogo className="bs-logo" />
          </Box>
          <Box py={1}>
            <TextField
              {...formFieldsConfig.two_factor_code}
              {...getFormikFields(FORM_FIELDS.two_factor_code)}
              endAdornment={
                <InputAdornment position="end">
                  <Pin />
                </InputAdornment>
              }
              fullWidth
            />
          </Box>
          <Box sx={VerifyCodeFormStyles.submitContainer}>
            <Button type="button" color="secondary" onClick={resendAuthCode}>
              {LABEL.resendButtonTitle}
            </Button>
            <Button
              type="submit"
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {LABEL.submitButtonTitle}
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  )
}

const LABEL = {
  submitButtonTitle: 'Verify Code',
  resendButtonTitle: 'Resend',
}

export default VerifyCode
