import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'redux/store'

export const selectCustomerAnalytics = (state: RootState) =>
  state.customerAnalytics

export const selectChartSettings = createSelector(
  selectCustomerAnalytics,
  (state) => state.chartSettings
)
