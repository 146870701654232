import { Box, SxProps } from '@mui/material'
import { Card, CardProps, CardSubTitle, CardTitle } from 'components/UI'
import DateRangePicker, {
  DateRangePickerProps,
} from 'components/Forms/DateRangePicker/DateRangePicker'

interface WidgetCardProps extends CardProps {
  /**
   * Wrapper styles of the right component
   */
  rightComponentWrapperStyles?: SxProps

  /**
   * Custom component that appears on right side
   */
  rightComponent?: JSX.Element

  /**
   * enables date range picker
   * @default false
   */
  enableDateRange?: boolean

  /**
   * Callback is fired when user changes date range
   */
  onDateRangeChange?: () => void

  /**
   * Date range props
   */
  dateRangeProps?: DateRangePickerProps
}

export default function WidgetCard({
  title,
  subTitle,
  children,
  rightComponentWrapperStyles,
  rightComponent,
  enableDateRange = false,
  dateRangeProps,
  ...otherProps
}: WidgetCardProps) {
  return (
    <Card {...otherProps}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <CardTitle label={title} />
          {subTitle && <CardSubTitle label={subTitle} />}
        </Box>

        {rightComponent && (
          <Box sx={rightComponentWrapperStyles}>{rightComponent}</Box>
        )}

        {enableDateRange && (
          <Box sx={{}}>
            <DateRangePicker {...dateRangeProps} />
          </Box>
        )}
      </Box>
      {children}
    </Card>
  )
}
