import { object, string } from 'yup'

export const FORM_FIELDS = {
  email: 'email',
  password: 'password',
}

export const formFieldsConfig = {
  initialValues: {
    email: '',
    password: '',
  },
  email: {
    placeholder: 'Email',
    name: FORM_FIELDS.email,
    type: 'email',
  },
  password: {
    placeholder: 'Password',
    name: FORM_FIELDS.password,
    type: 'password',
  },
}

export const validationSchema = object().shape({
  email: string().email().required(
    'Please enter email id.',
  ),
  password: string().required(
    'Please enter password.',
  ),
})
