import { FormHelperText, Input, InputBaseProps, SxProps } from '@mui/material'
import { COLORS } from 'styles'

export interface TextFieldProps extends InputBaseProps {
  /**
   * Error to show
   */
  helperText?: string
}

function TextField({ sx, helperText, error, ...props }: TextFieldProps) {
  return (
    <>
      <Input
        sx={
          [
            {
              backgroundColor: COLORS.BRIGHT_GRAY,
              paddingX: 2,
              paddingY: 0.5,
              border: '2px solid',
              borderColor: error ? 'error.main' : 'transparent',
              borderRadius: '5px',
            },
            sx,
          ] as SxProps
        }
        disableUnderline
        {...props}
      />
      {helperText && (
        <FormHelperText error={error}> {helperText} </FormHelperText>
      )}
    </>
  )
}

export default TextField
