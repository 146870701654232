import { selectAuthToken } from 'redux/Auth/auth.selectors'
import { useAppSelector } from 'redux/hooks'
import { useNavigate } from 'react-router-dom'
import { LOGIN_ROUTE } from 'helpers/Constants/path'
import { useEffect } from 'react'
// import {  } from 'react-router-dom'

const PrivateRoutes = (props) => {
  const navigate = useNavigate()
  const authToken = useAppSelector(selectAuthToken)
  useEffect(() => {
    if (!authToken) {
      // not logged in so redirect to login page with the return url
      navigate(LOGIN_ROUTE, { replace: true })
    }
  }, [authToken, navigate])

  // authorised so return component
  return props.children
}

export default PrivateRoutes
