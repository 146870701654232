import IconButtonMui, { IconButtonProps } from '@mui/material/IconButton'
import { forwardRef, ForwardedRef } from 'react'

const IconButton = forwardRef(
  (props: IconButtonProps, ref: ForwardedRef<HTMLButtonElement>) => (
    <IconButtonMui ref={ref} {...props} />
  )
)

export default IconButton
