import { API_URL } from 'helpers/Constants'
import axios, { AxiosRequestConfig } from 'axios'
import SnackbarUtils from 'components/UI/Toaster'
import { store } from 'redux/store'
import authActions from 'redux/Auth/auth.action'

const setHeaders = () => {
  const authToken = localStorage.getItem('auth_token')
  const headers = {
    'Content-Type': 'application/json',
  }
  if (authToken !== '') {
    // eslint-disable-next-line dot-notation
    headers['Authorization'] = `Bearer ${authToken}`
  }

  return headers
}

const axiosRequest = async (
  method: string,
  url: string,
  data: AxiosRequestConfig = {}
) => {
  const config: AxiosRequestConfig = {
    method,
    baseURL: API_URL,
    url,
    headers: { ...setHeaders(), ...data.headers },
    ...data,
  }

  try {
    const response = await axios(config)
    if (response.data.status === '1') {
      return response.data
    }

    throw new Error(response.data.message)
  } catch (error) {
    if (error.response.status === 401) {
      store.dispatch(authActions.userLogout())
    } else {
      SnackbarUtils.error(
        error?.response?.data?.message ??
          error.message ??
          'Error processing your request.'
      )
    }
  }
}

const getRequest = (url: string, data?: AxiosRequestConfig) =>
  axiosRequest('GET', url, data)

const postRequest = async (url: string, data?: AxiosRequestConfig) =>
  axiosRequest('POST', url, data)

const putRequest = async (url: string, data?: AxiosRequestConfig) =>
  axiosRequest('PUT', url, data)

const deleteRequest = async (url: string, data?: AxiosRequestConfig) =>
  axiosRequest('PUT', url, data)

const mockApi = async (data: any) => data

const apiHandler = {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  mockApi,
}

export default apiHandler
