export const HOME = ''
export const ANY_PATH = '*'
export const LOGIN_ROUTE = '/login'
export const VERIFY_CODE_ROUTE = '/verify-code'
export const FORGOT_PASSWORD_ROUTE = '/forgot-password'
export const VIEW_SUBPATH = '/view'

/* ORDERS */
export const ORDERS_WRAPPER = '/orders/*'
export const ORDERS = '/orders'
export const CRM = '/crm'
export const BOOKINGS = '/bookings'

/* USERS */
export const USERS_WRAPPER = '/user-analytics/*'
export const USERS = '/user-analytics'

/* SUBSCRIBERS */
export const SUBSCRIBERS = '/subscribers'

/* CUSTOMER */
export const CUSTOMER_WRAPPER = '/customer-analytics/*'
export const CUSTOMER = '/customer-analytics'

/* CRM USERS */
export const CRM_USERS_WRAPPER = '/crm-user/*'
export const CRM_USERS = '/crm-user'