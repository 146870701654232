import { SHOULD_MOCK_APIS } from 'helpers/Constants'
import {
  loginMockResponse,
  forgotPasswordResponse,
  verifyCodeResponse,
  logoutResponse,
  resendCodeResponse,
} from 'assets/mocks'
import apiHandler from 'helpers/ApiHandler'

const login = (email, password) =>
  SHOULD_MOCK_APIS
    ? apiHandler.mockApi(loginMockResponse)
    : apiHandler.postRequest('login', { data: { email, password } })

const forgotPassword = (email) =>
  SHOULD_MOCK_APIS
    ? apiHandler.mockApi(forgotPasswordResponse)
    : apiHandler.postRequest('forgotPassword', { data: { email } })

const verifyAuthentication = (twoFactorCode: number, code: string) =>
  SHOULD_MOCK_APIS
    ? apiHandler.mockApi(verifyCodeResponse)
    : apiHandler.postRequest('verifyAuthCode', {
        data: {
          two_factor_code: twoFactorCode,
          code,
        },
      })

const resendAuthenticationCode = (code: string) =>
  SHOULD_MOCK_APIS
    ? apiHandler.mockApi(resendCodeResponse)
    : apiHandler.postRequest('resendAuthCode', {
        data: {
          code,
        },
      })

const logout = () =>
  SHOULD_MOCK_APIS
    ? apiHandler.mockApi({
        error: false,
        data: logoutResponse,
      })
    : apiHandler.getRequest('logout')

const authenticationService = {
  login,
  forgotPassword,
  verifyAuthentication,
  logout,
  resendAuthenticationCode,
}

export default authenticationService
