import { Box, SxProps } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { COLORS } from 'styles'
import { Chart, ChartRef, WidgetCard } from 'components/UI'
import { topPieStyles } from './TopPie'

interface AreaChartProps {
  titleText: string
  data: {
    month: string
    total_orders: number
    total_amount: number
  }[]
}
const BOOKINGS_MULTIPLIER = 100

export default function AreaChart({ titleText, data }: AreaChartProps) {
  const [chartData, setChartData] = useState({ ...options })
  const chartComponent = useRef<ChartRef>()

  useEffect(() => {
    if (data) {
      setChartData({
        ...options,
        xAxis: [
          {
            categories: data?.map((value) => value.month),
            crosshair: true,
            lineColor: 'transparent',
            tickLength: 0,
          },
        ],
        series: [
          {
            name: 'Revenue',
            color: COLORS.PRIMARY,
            fillOpacity: 0.5,
            data: data?.map((value) => Math.round(value.total_amount)),
          },
          {
            name: 'Bookings',
            color: COLORS.TERTIARY,
            fillOpacity: 0.5,
            data: data?.map(
              (value) => value.total_orders * BOOKINGS_MULTIPLIER
            ),
          },
        ],
      })
      chartComponent?.current?.chart.reflow()
    }
  }, [data, titleText])

  return (
    <WidgetCard title={titleText}>
      {/* Chart  */}
      <Box
        sx={
          [
            topPieStyles.chartWrapper,
            areaPiAreaChartStyles.chartWrapper,
          ] as SxProps
        }
      >
        <Box sx={areaPiAreaChartStyles.chartSizeWrapper}>
          <Chart ref={chartComponent} options={{ ...chartData }} />
        </Box>
      </Box>
    </WidgetCard>
  )
}
interface AreaChartStylesType {
  titleText: SxProps
  chartWrapper: SxProps
  chartSizeWrapper: SxProps
}

export const areaPiAreaChartStyles: AreaChartStylesType = {
  titleText: {
    fontSize: 20,
  },
  chartWrapper: {
    my: 0,
    mt: 2,
  },
  chartSizeWrapper: {
    width: '100%',
  },
}

const options = {
  chart: {
    type: 'areaspline',
    height: '400px',
  },
  title: {
    text: '',
    align: 'left',
  },
  credits: false,
  xAxis: [
    // BOTTOM AXIS
    {
      categories: [
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
        'Jan',
        'Feb',
        'Mar',
        'Apr',
      ],
      crosshair: true,
      lineColor: 'transparent',
      tickLength: 0,
    },
  ],
  // left axis
  yAxis: {
    title: '',
    gridLineWidth: 0,
    labels: {
      x: 10,
    },
  },
  tooltip: {
    shared: true,
    // eslint-disable-next-line func-names, object-shorthand
    formatter: function (this, tooltip) {
      return `<b>${this.points[0].x}</b>
      <br/>${this.points[0].series.name}: ${this.points[0].y}$
      <br/>${this.points[1].series.name}: ${
        this.points[1].y / BOOKINGS_MULTIPLIER
      }
      `
    },
  },
  plotOptions: {
    areaspline: {
      stacking: 'normal',
      marker: {
        enabled: false,
      },
    },
  },
  series: [
    {
      name: 'Revenue',
      color: COLORS.PRIMARY,
      fillOpacity: 0.5,
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      name: 'Bookings',
      color: COLORS.TERTIARY,
      fillOpacity: 0.5,
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ],
}
