import { SxProps } from '@mui/material'
import { HEADER_HEIGHT } from 'helpers/Constants'
import { COLORS } from 'styles'

interface FormStylesTypes {
  wrapper: SxProps
  contentWrapper: SxProps
}

const dashboardStyles: FormStylesTypes = {
  wrapper: {
    display: 'flex',
    '.bs-logo': {
      height: 'auto',
      width: '150px',
    },
  },
  contentWrapper: {
    flexGrow: 1,
    p: 3,
    mt: `${HEADER_HEIGHT}px`,
    backgroundColor: COLORS.LIGHT_GREY,
    minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
  },
}

export default dashboardStyles
