import {
  Box,
  Drawer,
  DrawerProps,
  SxProps,
  Toolbar,
  Typography,
} from '@mui/material'
import Button, { ButtonProps, IconButton } from 'components/Forms/Button'
import { fontWeight } from 'styles/theme'
import { Close as CloseIcon } from '@mui/icons-material'
import { COLORS } from 'styles'
import { useAppBarHeight } from 'helpers/Hooks'

export interface RightSidebarProps extends DrawerProps {
  /**
   * Modal title
   */
  headerText: string
  /**
   * additional buttons that will show up at bottom
   */
  footerActions?: FooterAction[]
  onClose: () => void

  /**
   * additional body styles
   */
  bodyStyles?: SxProps
}

interface FooterAction extends ButtonProps {
  /**
   * button label
   */
  label: string
}

const SIZES = {
  header: 60,
  footer: 60,
}

export default function RightSidebar({
  sx,
  onClose,
  children,
  headerText,
  footerActions = [],
  bodyStyles = {},
  ...otherProps
}: RightSidebarProps) {
  const appBarHeight = useAppBarHeight()

  return (
    <Drawer
      anchor="right"
      onClose={onClose}
      sx={{ '.MuiPaper-root': { overflowY: 'hidden' } }}
      {...otherProps}
    >
      <Toolbar />

      <Box
        sx={{
          width: Math.min(350, window.innerWidth),
          height: '100%',
        }}
      >
        {/* Header */}
        <Box
          sx={{
            height: SIZES.header,
            p: 1.5,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: `1px solid ${COLORS.BRIGHT_GRAY}`,
          }}
        >
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: fontWeight.medium,
            }}
          >
            {headerText}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Body */}
        <Box
          sx={
            [
              {
                height: `calc(100vh - ${SIZES.header}px - ${SIZES.footer}px - ${appBarHeight}px)`,
                p: 1.5,
                overflowY: 'auto',
              },
              bodyStyles,
            ] as SxProps
          }
        >
          {children}
        </Box>

        {/* Footer */}
        <Box
          sx={{
            height: SIZES.footer,
            p: 1.5,
            display: 'flex',
          }}
        >
          {footerActions.map((action, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Button sx={{ mx: 0.5, minWidth: 100 }} {...action} key={index}>
              {action.label}
            </Button>
          ))}
          <Button
            sx={{ mx: 0.5, minWidth: 100 }}
            variant="outlined"
            onClick={onClose}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Drawer>
  )
}
