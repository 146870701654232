import { CSSObject, SxProps, Theme } from '@mui/material'
import { DRAWER_WIDTH, HEADER_HEIGHT } from 'helpers/Constants'
import { COLORS } from 'styles'

interface SidebarStylesTypes {
  drawer: SxProps
  drawerOpen: SxProps
  drawerClose: SxProps
  drawerHeader: SxProps
  sidebarItemWrapper: SxProps
  sidebarItem: SxProps
  sidebarItemButton: SxProps
  sidebarItemButtonActive: SxProps
  sidebarItemButtonOpen: SxProps
  sidebarItemIconWrapper: SxProps
  sidebarItemIcon: SxProps
  sidebarItemLink: SxProps
  sidebarItemTextActive: SxProps
}

const sidebarStyles: SidebarStylesTypes = {
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '& .MuiDrawer-paper': { backgroundColor: COLORS.DARK_BLUE },
  },
  drawerOpen: (theme: Theme): CSSObject => ({
    ...openedDrawerStyles(theme),
    '& .MuiDrawer-paper': openedDrawerStyles(theme),
  }),
  drawerClose: (theme: Theme): CSSObject => ({
    ...closeDrawerStyles(theme),
    '& .MuiDrawer-paper': closeDrawerStyles(theme),
  }),
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingY: 1,
    height: HEADER_HEIGHT,
  },

  sidebarItemWrapper: {},
  sidebarItem: { display: 'block', paddingY: 0.5 },
  sidebarItemButton: {
    minHeight: 48,
    justifyContent: 'center',
    px: 1,
    borderLeft: '2px solid transparent',
  },
  sidebarItemButtonActive: {
    borderColor: COLORS.HIGHLIGHT_BLUE,
  },
  sidebarItemButtonOpen: {
    justifyContent: 'initial',
  },
  sidebarItemIconWrapper: {
    justifyContent: 'center',
  },
  sidebarItemIcon: {
    color: COLORS.WHITE,
  },
  sidebarItemLink: {
    color: COLORS.WHITE,
  },
  sidebarItemTextActive: {
    color: COLORS.HIGHLIGHT_BLUE,
  },
}

const openedDrawerStyles = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closeDrawerStyles = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

export default sidebarStyles
