import { createAction } from '@reduxjs/toolkit'
import {
  ChartSettingsType,
  GET_CHART_SETTINGS_ACTION,
  RESET_CHART_SETTINGS_ACTION,
  SAVE_CHART_SETTINGS_ACTION,
} from './customerAnalytics.types'

const customerAnalyticsActions = {
  getChartSettings: createAction<void>(GET_CHART_SETTINGS_ACTION),
  saveChartSettings: createAction<ChartSettingsType[]>(
    SAVE_CHART_SETTINGS_ACTION
  ),
  resetChartSettings: createAction<void>(RESET_CHART_SETTINGS_ACTION),
}

export default customerAnalyticsActions
