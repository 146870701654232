import { RightSideBar, RightSidebarProps } from 'components/Navigation/Sidebar'
import { selectChartSettings } from 'redux/CustomerAnalytics/customerAnalytics.selectors'
import { useAppSelector } from 'redux/hooks'
import { useEffect, useState } from 'react'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  SxProps,
  Typography,
  formControlLabelClasses,
  radioClasses,
} from '@mui/material'
import { fontWeight } from 'styles/theme'
import { useDispatch } from 'react-redux'
import customerAnalyticsActions from 'redux/CustomerAnalytics/customerAnalytics.action'
import { COLORS } from 'styles'
import { ChartSettingsType } from 'redux/CustomerAnalytics/customerAnalytics.types'
import { getKeysWithSimilarValues } from 'helpers/Methods'
import { COMPATIBLE_CHARTS } from 'helpers/Constants'

interface ChartSettingsProps extends Omit<RightSidebarProps, 'headerText'> {}

export default function ChartSettings({
  onClose,
  ...props
}: ChartSettingsProps) {
  const chartSettings = useAppSelector(selectChartSettings)
  const [charts, setCharts] = useState<ChartSettingsType[]>([])
  const dispatch = useDispatch()

  const onCheckboxChange = (chart, index: number) => {
    const newCharts = [...charts]
    newCharts[index].visible = !newCharts[index].visible
    setCharts(newCharts)
  }

  const onChartTypeChange = (value, index: number) => {
    const newCharts = [...charts]
    newCharts[index].type = value
    setCharts(newCharts)
  }

  const onSave = () => {
    dispatch(customerAnalyticsActions.saveChartSettings(charts))
    onClose()
  }

  const onReset = () => {
    dispatch(customerAnalyticsActions.resetChartSettings())
    onClose()
  }

  useEffect(() => {
    setCharts(chartSettings.map((chart) => ({ ...chart })))
  }, [chartSettings])

  return (
    <RightSideBar
      headerText="Chart Settings"
      onClose={onClose}
      footerActions={[
        {
          label: 'Save',
          onClick: onSave,
        },
        {
          label: 'Reset',
          variant: 'text',
          onClick: onReset,
        },
      ]}
      bodyStyles={chartSettingsStyles.wrapper}
      {...props}
    >
      <Box>
        <Typography
          sx={{ color: COLORS.PRIMARY_DARK, fontWeight: fontWeight.bold }}
        >
          Charts
        </Typography>
      </Box>
      {charts.map((chart, index) => (
        <Box
          sx={
            [
              chartSettingsStyles.settingItem,
              !chart.visible && chartSettingsStyles.settingItemInActive,
            ] as SxProps
          }
          key={chart.dataKey}
        >
          <Box sx={chartSettingsStyles.itemHeading}>
            <Box sx={chartSettingsStyles.titleWrapper}>
              <Typography sx={chartSettingsStyles.title}>
                {chart.props.title}
              </Typography>
            </Box>
            <Box sx={chartSettingsStyles.checkboxWrapper}>
              <Checkbox
                checked={chart.visible}
                onChange={() => {
                  onCheckboxChange(chart, index)
                }}
              />
            </Box>
          </Box>

          <Box sx={chartSettingsStyles.chartTypeWrapper}>
            <FormControl>
              <FormLabel
                sx={chartSettingsStyles.chartTypeTitle}
                id={`chart-type-${index}`}
              >
                Chart Type
              </FormLabel>
              <RadioGroup
                aria-labelledby={`chart-type-${index}`}
                value={chart.type}
                sx={chartSettingsStyles.chartTypeRadioGroup}
                onChange={(e) => {
                  onChartTypeChange(e.target.value, index)
                }}
              >
                {getKeysWithSimilarValues(COMPATIBLE_CHARTS, chart.type).map(
                  (value) => (
                    <FormControlLabel
                      sx={chartSettingsStyles.chartTypeRadio}
                      value={value}
                      control={<Radio size="small" />}
                      label={value}
                      key={value}
                    />
                  )
                )}
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      ))}
    </RightSideBar>
  )
}

interface ChartSettingsStyles {
  wrapper: SxProps
  sectionHeadingWrapper: SxProps
  sectionHeading: SxProps
  settingItem: SxProps
  settingItemInActive: SxProps
  itemHeading: SxProps
  titleWrapper: SxProps
  title: SxProps
  checkboxWrapper: SxProps

  chartTypeWrapper: SxProps
  chartTypeTitle: SxProps
  chartTypeRadioGroup: SxProps
  chartTypeRadio: SxProps
}

const chartSettingsStyles: ChartSettingsStyles = {
  wrapper: {
    backgroundColor: COLORS.BRIGHT_GRAY,
  },
  sectionHeadingWrapper: {},
  sectionHeading: {},
  settingItem: {
    backgroundColor: COLORS.WHITE,
    borderRadius: '5px',
    px: 1,
    pb: 1.5,
    my: 1,
    transition: '.3s',
  },
  settingItemInActive: {
    opacity: 0.7,
  },
  itemHeading: {
    display: 'flex',
    alignItems: 'center',
  },
  titleWrapper: {
    width: `calc(100% - 50px)`,
  },
  title: {
    fontSize: 14,
    fontWeight: fontWeight.medium,
  },
  checkboxWrapper: {
    width: 50,
  },
  chartTypeWrapper: {},
  chartTypeTitle: {
    fontSize: 12,
  },
  chartTypeRadioGroup: {
    flexDirection: 'row',
    px: 1,
  },
  chartTypeRadio: {
    [`& .${radioClasses.root}`]: {
      p: 0.5,
    },
    [`& .${formControlLabelClasses.label}`]: {
      fontSize: 14,
      textTransform: 'capitalize',
    },
  },
}
