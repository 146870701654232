import { useCallback, useEffect, useState } from 'react'
import { Box, Grid, SxProps } from '@mui/material'
import { PageTitle } from 'components/UI'
import { CHART_THEME } from 'helpers/Constants'
import Highcharts from 'highcharts'
import SnackbarUtils from 'components/UI/Toaster'
import { dashboardService } from 'services'
import { fontFamily } from 'styles/theme'
import {
  CalendarDateRangeType,
  DateRangePicker,
} from 'components/Forms'
import { formatDateForApi } from 'helpers/Methods'
import { COLORS } from 'styles'
import { BarChart, Bookings, SimpleState, TopPie } from './Widgets'

export type TodaysBookings = {
  id: number | string
  bookingNum: string
  created: string
  name: string
  price: number
  status: string
}

interface dashboardDataObject {
  newUsers?: number
  newBookings?: number
  profit?: number
  profitPercent?:number
  revenueDataPerMonth?: {
    x_axis: string
    // total_orders: number
    y_axis: number
  }[]
  usersDataPerMonth?: {
    x_axis: string
    y_axis: number
  }[]
  revenueByDomain?: {
    total_revenue: number
    domain: string
  }[]
  eventsPerMonth?: {
    x_axis: string
    y_axis: number
  }[]
  todaysBookings?: TodaysBookings[]
  yesterdaysBookings?: TodaysBookings[]
  dayBeforeYesterdaysBookings?: TodaysBookings[]
}

function Dashboard() {
  const [dashboardData, setDashboardData] = useState<dashboardDataObject>({
    todaysBookings: [],
    yesterdaysBookings: [],
    dayBeforeYesterdaysBookings: [],
    eventsPerMonth: []
  })

  const [dashboardAnalyticsData, setDashboardAnalyticsData] = useState<dashboardDataObject>({
    revenueByDomain: [],
    newUsers: 0,
    newBookings: 0,
    profit: 0,
    profitPercent: 0,
  })

  const [analyticsDateRange, setAnalyticsDateRange] =
    useState<CalendarDateRangeType>([new Date(), new Date()])

  const getDashboardAnalyticsData = useCallback(async () => {
    try {
      const response = await dashboardService.dashboardAnalytics({
        params: {
          startDate: formatDateForApi(analyticsDateRange[0]),
          endDate: formatDateForApi(analyticsDateRange[1]),
        },
      })

      if (response) {
        setDashboardAnalyticsData({
          newUsers: response.newUsers,
          newBookings: response.newBookings,
          profit: response.profit,
          profitPercent: response.profitPercent,
          revenueByDomain: response.revenueByDomain,
        })
      }
    } catch (error) {
      SnackbarUtils.error('Error Processing your request!')
    }
  }, [analyticsDateRange])

  useEffect(() => {
    getDashboardData()
  }, [])

  useEffect(() => {
    getDashboardAnalyticsData()
  }, [analyticsDateRange, getDashboardAnalyticsData])

  const getDashboardData = async () => {
    try {
      const response = await dashboardService.dashboard()
      if (response) {
        setDashboardData(response)
      }
    } catch (error) {
      SnackbarUtils.error('Error Processing your request!')
    }
  }

  return (
    <Box>
      <Box sx={dashboardStyles.pageHeaderWrapper}>
        <PageTitle>Dashboard</PageTitle>

        {/* Toolbar */}
        <Box sx={dashboardStyles.toolsWrapper}>
          <DateRangePicker
            value={analyticsDateRange}
            onChange={(range) => {
              setAnalyticsDateRange(range)
            }}
            disableFutureDates
            // displayValue
            customDisplayCalendarIcon
          />
        </Box>
      </Box>

      <Grid container spacing={2} sx={{ mt: 1 }}>
        {/* <Grid item sm={12} md={6}>
          <Grid container spacing={2}>
            <Grid item md={6} sm={6} xs={12}>
              <SimpleState
                // Icon={GroupAddIcon}
                dateRangeProps={{
                  value: usersDateRange,
                  onChange: (range) => {
                    setUsersDateRange(range)
                  },
                  disableFutureDates: true,
                }}
                enableDateRange
                state={userCounts}
                label="New Subscribers"
              />
            </Grid>

            <Grid item md={6} sm={6} xs={12}>
              <SimpleState
                // Icon={LocalActivityIcon}
                state={bookingCounts}
                label="Total Bookings"
                theme={CHART_THEME.SECONDARY}
                dateRangeProps={{
                  value: analyticsDateRange,
                  onChange: (range) => {
                    setAnalyticsDateRange(range)
                  },
                  disableFutureDates: true,
                }}
                enableDateRange
              />
            </Grid>

            <Grid item sm={12}>
              <BarChart
                titleText="Users This Year"
                chartHeight="240px"
                data={dashboardData.usersDataPerMonth}
              />
            </Grid>
          </Grid>
        </Grid> */}

        {/* New Subscribers */}
        <Grid item md={3} sm={6} xs={12}>
          <SimpleState
            state={dashboardAnalyticsData.newUsers}
            label="New Subscribers"
          />
        </Grid>

        {/* Total Bookings  */}
        <Grid item md={3} sm={6} xs={12}>
          <SimpleState
            // Icon={LocalActivityIcon}
            state={dashboardAnalyticsData.newBookings}
            label="Total Bookings"
            theme={CHART_THEME.SECONDARY}
          />
        </Grid>

        {/* Total Profit  */}
        <Grid item md={3} sm={6} xs={12}>
          <SimpleState
            // Icon={LocalActivityIcon}
            state={`$${dashboardAnalyticsData.profit}`}
            label="Total Profit"
            theme={CHART_THEME.TERTIARY}
          />
        </Grid>

        {/* Total Profit Percentage */}
        <Grid item md={3} sm={6} xs={12}>
          <SimpleState
            // Icon={LocalActivityIcon}
            state={`${dashboardAnalyticsData.profitPercent}%`}
            label="Profit Percentage"
            theme={CHART_THEME.DEEP_SAFFRON}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <BarChart
            titleText="Monthly Subscribers"
            chartHeight="360px"
            data={dashboardData.usersDataPerMonth}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <BarChart
            titleText="Monthly Revenue"
            chartHeight="360px"
            data={dashboardData.revenueDataPerMonth}
            currency
          />
          {/* <AreaChart
            titleText="Sales This year"
            data={dashboardData.revenueDataPerMonth}
          /> */}
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <BarChart
            titleText="Event Dates By Month"
            chartHeight="350px"
            data={dashboardData.eventsPerMonth}
            currency
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <TopPie
            titleText="Sales"
            data={dashboardAnalyticsData.revenueByDomain}
            chartHeight="305px"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Bookings
            todayBookings={dashboardData.todaysBookings}
            yesterdayBookings={dashboardData.yesterdaysBookings}
            dayBeforeYesterdayBookings={
              dashboardData.dayBeforeYesterdaysBookings
            }
          />
        </Grid>
      </Grid>
    </Box>
  )
}

interface DashboardStyles {
  pageHeaderWrapper: SxProps
  toolsWrapper: SxProps
  toolIconButton: SxProps
  toolIcon: SxProps
}

const dashboardStyles: DashboardStyles = {
  pageHeaderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  toolsWrapper: {
    display: 'flex',
    padding: '0 10px',
    border: `1px solid ${COLORS.CADET_BLUE}`,
    borderRadius: '50px',
  },
  toolIconButton: {
    p: 0.5,
  },
  toolIcon: {
    color: COLORS.BLACK,
    '&:hover': { color: COLORS.PRIMARY_DARK },
  },
}

Highcharts.setOptions({
  chart: {
    style: {
      fontFamily,
    },
  },
  accessibility: {
    enabled: false,
  },
})

export default Dashboard
