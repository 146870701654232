import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export interface LinkProps extends MuiLinkProps {}

export default function Link({ href, ...otherProps }: LinkProps) {
  const navigate = useNavigate()

  const clickHandler = useCallback(() => {
    navigate(href)
  }, [href, navigate])

  return (
    <MuiLink
      onClick={clickHandler}
      {...otherProps}
    />
  )
}
