import { createAction } from '@reduxjs/toolkit'
import { AuthState } from './auth.state'
import { UPDATE_AUTH_STATE, USER_LOGOUT } from './auth.types'

const authActions = {
  updateAuthState: createAction<AuthState>(UPDATE_AUTH_STATE),
  userLogout: createAction<AuthState>(USER_LOGOUT),
}

export default authActions
