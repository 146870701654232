import { Box, InputAdornment, SxProps } from '@mui/material'
import { Button, TextField } from 'components/Forms'
import {
  Card,
  Chip,
  PageTitle,
  PaginationData,
  Table,
  TableHeader,
} from 'components/UI'
import {
  Search as SearchIcon,
  Tune as FilterIcon,
  Clear as ClearIcon,
  // CloudDownload as CloudDownloadIcon,
} from '@mui/icons-material'
import { useCallback, useEffect, useState } from 'react'
import { newsLetterSubscribersService } from 'services'
import { useDebounce } from 'use-debounce'
import RightSidebar from 'components/Navigation/Sidebar/RightSidebar'
import { NavigateFunction, useUserNavigate } from 'helpers/Hooks'

export default function NewsLetterSubscribers() {
  const [newsLetterSubscribersList, setNewsLetterSubscribersList] = useState([])
  const [searchKeyword, setSearchKeyword] = useState('')
  const [debouncedQuery] = useDebounce(searchKeyword, 500)
  const [pagination, setPagination] = useState<PaginationData>({
    count: 0,
    page: 0,
    rowsPerPage: 10,
  })

  const [isFiltersOpen, setFiltersOpen] = useState(false)
  const navigate = useUserNavigate()

  const fetchNewsLetterSubscribers = useCallback(async () => {
    const response = await newsLetterSubscribersService.newsLetterSubscribers({
      params: {
        rowsPerPage: 10,
        page: pagination.page,
        search: debouncedQuery,
      },
    })
    setNewsLetterSubscribersList(response.data)
    setPagination({
      page: pagination.page,
      count: response.total,
      rowsPerPage: parseInt(response.perPage, 10),
    })
  }, [debouncedQuery, pagination.page])

  // const exportNewsLetter = async () => {
  //   const response = await newsLetterSubscribersService.exportNewsLetterSubscribers()
  //   CsvDownloader(response.columns, response.data, 'NewsLetterSubscribers.csv')
  // }

  const toggleFilters = useCallback(() => {
    setFiltersOpen(!isFiltersOpen)
  }, [isFiltersOpen])

  useEffect(() => {
    fetchNewsLetterSubscribers()
  }, [fetchNewsLetterSubscribers, pagination.page, debouncedQuery])

  return (
    <Box>
      <Box>
        <PageTitle>{CONTENT_LABELS.pageTitle}</PageTitle>
      </Box>

      <Card>
        <Box sx={newsLetterSubscribersStyles.titleWrapper}>
          <Box sx={newsLetterSubscribersStyles.searchWrapper}>
            <TextField
              placeholder="Search NewsLetter Subscriber"
              value={searchKeyword}
              onChange={(e) => {
                setSearchKeyword(e.target.value)
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              endAdornment={
                searchKeyword && (
                  <InputAdornment position="end">
                    <ClearIcon
                      onClick={() => setSearchKeyword('')}
                      sx={{ cursor: 'pointer' }}
                    />
                  </InputAdornment>
                )
              }
              fullWidth
            />
          </Box>

          <Box sx={newsLetterSubscribersStyles.toolsWrapper}>
            <Button
              variant="outlined"
              sx={newsLetterSubscribersStyles.button}
              startIcon={<FilterIcon />}
              onClick={toggleFilters}
            >
              {CONTENT_LABELS.filterButton}
            </Button>
            <Button sx={newsLetterSubscribersStyles.button}>
              {CONTENT_LABELS.addButton}
            </Button>
            {/* <Button
              sx={newsLetterSubscribersStyles.button}
              startIcon={<CloudDownloadIcon />}
              onClick={exportNewsLetter}
            >
              {CONTENT_LABELS.downloadCSV}
            </Button> */}
          </Box>
        </Box>

        <Box sx={newsLetterSubscribersStyles.tableWrapper}>
          <Table
            columns={tableHeaders(navigate)}
            data={newsLetterSubscribersList}
            pagination={{
              ...pagination,
              onPageChange: (e, page) => {
                setPagination({
                  ...pagination,
                  page,
                })
              },
            }}
          />
        </Box>
      </Card>

      <RightSidebar
        open={isFiltersOpen}
        onClose={toggleFilters}
        headerText={CONTENT_LABELS.filterModalHeader}
        footerActions={[
          {
            label: 'Save',
            onClick: toggleFilters,
          },
        ]}
      >
        Some Data here
      </RightSidebar>
    </Box>
  )
}

interface NewsLetterSubscribersStylesType {
  titleWrapper: SxProps
  searchWrapper: SxProps
  toolsWrapper: SxProps
  tableWrapper: SxProps
  button: SxProps
}

const newsLetterSubscribersStyles: NewsLetterSubscribersStylesType = {
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchWrapper: { width: 300 },
  toolsWrapper: {},
  tableWrapper: {
    mt: 2,
  },
  button: {
    mx: 0.5,
  },
}

const CONTENT_LABELS = {
  pageTitle: 'NewsLetter Subscribers',
  filterButton: 'Filters',
  addButton: 'Add NewsLetter Subscriber',
  filterModalHeader: 'Filter Data',
  // downloadCSV: 'CSV',
}

const tableHeaders = (navigate: NavigateFunction): TableHeader[] => [
  {
    name: 'Name',
    key: 'name',
  },
  { name: 'Email', key: 'email' },
  {
    name: 'Subscribed',
    key: 'subscribed',
    render: (value) => (
      <Chip
        size="small"
        label={value === 1 ? 'Yes' : 'No'}
        color={value === 1 ? 'success' : 'error'}
        borderLess
      />
    ),
  },
  { name: 'Subscribed Date', key: 'created_at' },
]
