import {
  Chart,
  ChartOptionsProps,
  ChartRef,
  chartBaseOptions,
} from 'components/UI'
import { useRef, useState, useEffect } from 'react'

export default function SplineChart(props: ChartOptionsProps) {
  const [chartData, setChartData] = useState(chartOptions(props))

  const chartComponent = useRef<ChartRef>()

  useEffect(() => {
    setChartData(chartOptions(props))
    chartComponent?.current?.chart.reflow()
  }, [props])

  return <Chart ref={chartComponent} options={{ ...chartData }} />
}

const chartOptions = (options: ChartOptionsProps) => ({
  ...chartBaseOptions('spline', options, {
    tooltip: {
      // pointFormat: `{y} ${options.yAxisTitle}: {point.y}`,
      formatter() {
        return `<b>${options?.series[0]?.name}</b><br/>${this?.x}: ${
          options?.isRevenue
        ?'$':''}${Math.round(this?.y)}`
      },
    },
    plotOptions: {
      spline: {
        lineWidth: 2,
        states: {
          hover: {
            lineWidth: 3,
          },
        },
        marker: {
          enabled: false,
        },
      },
    },
  }),
})
