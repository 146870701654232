import {
  Chart,
  ChartOptionsProps,
  ChartRef,
  chartBaseOptions,
} from 'components/UI'
import { PlotBubbleJitterOptions } from 'highcharts'
import { useRef, useState, useEffect } from 'react'

interface ScatterChartProps extends ChartOptionsProps {
  /**
   * randomly distribute points on axis. useful when there is so much data
   * @default x = 0.005
   */
  // eslint-disable-next-line react/no-unused-prop-types
  jitter?: PlotBubbleJitterOptions
}

export default function ScatterChart(props: ScatterChartProps) {
  const [chartData, setChartData] = useState(chartOptions(props))

  const chartComponent = useRef<ChartRef>()

  useEffect(() => {
    setChartData(chartOptions(props))
    chartComponent?.current?.chart.reflow()
  }, [props])

  return <Chart ref={chartComponent} options={{ ...chartData }} />
}

const chartOptions = ({ jitter, ...options }: ScatterChartProps) =>
  chartBaseOptions('scatter', options, {
    tooltip: {
      // pointFormat: `{y} ${options.yAxisTitle}: {point.y}`,
      formatter() {
        return `<b>${options?.series[0]?.name}</b><br/>${this?.x}: ${Math.round(
          this?.y
        )}`
      },
    },
    plotOptions: {
      scatter: {
        marker: {
          radius: 3.5,
          symbol: 'circle',
          states: {
            hover: {
              enabled: true,
              lineColor: 'rgb(100,100,100)',
            },
          },
        },
        states: {
          hover: {
            marker: {
              enabled: true,
            },
          },
        },
        jitter: jitter ?? {
          x: 0.005,
        },
      },
    },
  })
