import { createSlice } from '@reduxjs/toolkit'
import { CHART_SETTINGS_KEY, initialState } from './customerAnalytics.state'
import customerAnalyticsActions from './customerAnalytics.action'
import { chartSettings } from './customerAnalytics.config'

export const customerAnalyticsSlice = createSlice({
  name: 'customerAnalytics',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(
      customerAnalyticsActions.saveChartSettings,
      (state, action) => {
        state.chartSettings = action.payload
        localStorage.setItem(CHART_SETTINGS_KEY, JSON.stringify(action.payload))
      }
    )
    builder.addCase(customerAnalyticsActions.resetChartSettings, (state) => {
      state.chartSettings = [...chartSettings]
      localStorage.setItem(CHART_SETTINGS_KEY, JSON.stringify(chartSettings))
    })
  },
})

export default customerAnalyticsSlice.reducer
