import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableContainerProps,
  TableProps as MuiTableProps,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  TablePaginationProps,
} from '@mui/material'
import { COLORS } from 'styles'
import { fontWeight } from 'styles/theme'

export type TableHeader = {
  name: string
  key: string
  render?: (value, allData) => JSX.Element | string
}

interface TableProps extends MuiTableProps {
  /**
   * table container props
   */
  containerProps?: TableContainerProps
  /**
   * table header props
   */
  columns: TableHeader[]

  /**
   * data to display
   */
  data: any[]

  /**
   * table pagination props
   */
  pagination?: TablePaginationProps
}

export type PaginationData = {
  count: number
  rowsPerPage: number
  page: number
}

export default function Table({
  columns,
  data,
  containerProps,
  pagination,
  ...otherProps
}: TableProps) {
  return (
    <TableContainer {...containerProps}>
      <MuiTable {...otherProps}>
        <TableHead>
          <TableRow>
            {columns.map((value) => (
              <TableCell
                key={value.key}
                sx={{
                  fontWeight: fontWeight.bold,
                  color: COLORS.LIGHT_TEXT,
                  paddingY: 1.5,
                }}
              >
                {value.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((value) => (
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              key={value.id}
            >
              {columns.map((headerValue) => (
                <TableCell
                  component="td"
                  scope="row"
                  key={`${headerValue.key}-${value.id}`}
                  sx={{
                    fontWeight: fontWeight.medium,
                  }}
                >
                  {headerValue.render
                    ? headerValue.render(value[headerValue.key], value)
                    : value[headerValue.key]}
                </TableCell>
              ))}
            </TableRow>
          ))}

          {!data?.length && (
            <TableRow>
              <TableCell
                component="td"
                scope="row"
                colSpan={columns.length}
                sx={{ textAlign: 'center' }}
              >
                No Data Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>

        {pagination && (
          <TableFooter>
            <TableRow>
              <TablePagination
                sx={{ border: 0 }}
                colSpan={columns.length}
                rowsPerPageOptions={[pagination.rowsPerPage]}
                {...pagination}
              />
            </TableRow>
          </TableFooter>
        )}
      </MuiTable>
    </TableContainer>
  )
}
