import { ANY_PATH, VIEW_SUBPATH } from 'helpers/Constants/path'
import { Route, Routes } from 'react-router-dom'
import ViewUser from './ViewUser'
import UserList from './UserList'

export default function Users() {
  return (
    <Routes>
      <Route path={VIEW_SUBPATH} element={<ViewUser />} />
      <Route path={ANY_PATH} element={<UserList />} />
    </Routes>
  )
}
